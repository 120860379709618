export const Images = {
  LoginLogo: "../assets/agentlogo.png",
  Loginbg: "../assets/login-bg.png",
  HeaderLogo: "../assets/t2tlogo.png",
  ProfileImage: "../assets/profileimage.png",
  Kedarnath: "../assets/kedarnath.png",
  Lake: "../assets/lake.png",
  Boy: "../assets/boy.png",
  IPL: "../assets/ipl.png",
  Kedarnath: "../assets/kedarnath.png",
  Scene: "../assets/scene.png",
  CasinoTour: "../assets/casinotour.png",
  Pic1: "../assets/pic1.png",
  note: "../assets/note.png",
  adminPic: "../assets/adminpic.png",
  TourVideo1: "../assets/tourfirstvideos.png",
  TourVideo2: "../assets/toursecondvideos.png",
  TourVideo3: "../assets/tourthirdvideos.png",
  AllPhoto1: "../assets/allphoto1.png",
  AllPhoto2: "../assets/allphoto2.png",
  AllPhoto3: "../assets/allphoto3.png",
  AllPhoto4: "./assets/allphoto4.png",
  CasinoTourImg: "../assets/casinoTourImg.png",
  AdventureTourImg: "../assets/AdventureTours.png",
  SportsTourImg: "../assets/SportsTour.png",
  ProfileImage: "../assets/profileimage.png",
  Kedarnath: "../assets/kedarnath.png",
  Tour1: "../assets/tour1.png",
  Tour2: "../assets/tour2.png",
  Tour3: "../assets/tour3.png",
  Tour4: "../assets/tour4.png",
  Tour5: "../assets/tour5.png",
  Tour6: "../assets/tour6.png",
  Lake: "../assets/lake.png",
  IPL: "../assets/ipl.png",
  Football: "../assets/football.png",
  Nature: "../assets/scene.png",
  Boy: "../assets/boy.png",
  Casino: "../assets/casino.png",
  TourVideo1: "../assets/tourfirstvideos.png",
  TourVideo2: "../assets/toursecondvideos.png",
  TourVideo3: "../assets/tourthirdvideos.png",
  AllPhoto1: "../assets/allphoto1.png",
  AllPhoto2: "../assets/allphoto2.png",
  AllPhoto3: "../assets/allphoto3.png",
  AllPhoto4: "../assets/allphoto4.png",
  chatpic1: "../assets/chatpic1.png",
  chatpic2: "../assets/chatpic2.png",
  chatpic3: "../assets/chatpic3.png",
  chatpic4: "../assets/chatpic4.png",
  chatpic5: "../assets/chatpic5.png",
  chatpic6: "../assets/chatpic6.png",
  chatpic7: "../assets/chatpic7.png",
  chatpic8: "../assets/chatpic8.png",
  onlinepic1: "../assets/onlinepic1.png",
  onlinepic2: "../assets/onlinepic2.png",
  onlinepic3: "../assets/onlinepic3.png",
  addfrnd1: "../assets/addfrnd1.png",
  addfrnd2: "../assets/addfrnd2.png",
  addfrnd3: "../assets/addfrnd3.png",
  addfrnd4: "../assets/addfrnd4.png",
  CasinoTourImg: "../assets/casinoTourImg.png",
  AdventureTourImg: "../assets/AdventureTours.png",
  SportsTourImg: "../assets/SportsTour.png",
  MusicImage: "../assets/musicimage.jpg",
  CasinoBanner: "../assets/casino_banner.png",
  CardImage: "../assets/card_img_01.png",
  CardImage2: "../assets/card_img_02.png",
  successImg:"../assets/success.jpg"
};
