import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { api } from "../../api";
import { useNavigate } from "react-router";

function ForgetPassword({ user_id }) {
  console.log(user_id);
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [newPswd, setNewPswd] = useState("");
  const [confrimPswd, setConfirmPswd] = useState("");
  //   update password
  const data = {
    new_password: newPswd,
    confirm_password: confrimPswd,
    userId: user_id,
  };
  console.log(data, "updatePassword");

  const updatePassword = async () => {
    try {
      const response = await api.updatePassword(data);
      console.log(response, "response");
      setNewPswd("");
      setConfirmPswd("");
      return response;
    } catch (error) {
      console.error(error.message, "while doing update password");
    }
  };

  return (
    <div className="white-bg">
      <div className="d-flex flex-column">
        <div className="black-text font-16 fw-600 my-1">
          New Password <span className="star">*</span>
        </div>
        <div className="input-bg br-10 my-1 d-flex align-items-center">
          <input
            type={showNewPassword ? "text" : "password"}
            name="new_password"
            placeholder="Enter New Password"
            value={newPswd}
            onChange={(e) => setNewPswd(e.target.value)}
            className="grey-text w-100 p-2 bg-none"
          />
          {showNewPassword ? (
            <AiFillEyeInvisible
              className="font-30 clr-grey cursor-pointer"
              onClick={() => setShowNewPassword(!showNewPassword)}
            />
          ) : (
            <AiFillEye AiFillEyeInvisible
              className="font-30 clr-grey cursor-pointer"
              onClick={() => setShowNewPassword(!showNewPassword)}
            />
          )}
        </div>
      </div>

      <div className="d-flex flex-column">
        <div className="black-text font-16 fw-600 my-1">
          Confirm Password <span className="star">*</span>
        </div>
        <div className="input-bg br-10 my-1 d-flex align-items-center">
          <input
            type={showConfirmPassword ? "text" : "password"}
            name="confirm_password"
            placeholder="Confirm New Password"
            value={confrimPswd}
            onChange={(e) => setConfirmPswd(e.target.value)}
            className="grey-text w-100 p-2 bg-none"
          />
          {showConfirmPassword ? (
            <AiFillEyeInvisible
              className="font-30 clr-grey cursor-pointer"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            />
          ) : (
            <AiFillEye
              className="font-30 clr-grey cursor-pointer"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            />
          )}
        </div>
      </div>
      <div
        className="pink-bg py-2 br-20 clr-white fw-700 text-center my-2 pointer"
        onClick={() => {
          updatePassword();
          navigate("/");
        }}
      >
        Update Password
      </div>
    </div>
  );
}

export default ForgetPassword;
