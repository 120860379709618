import React, { useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonSearchbar,
  IonList,
  IonItem,
  IonLabel,
  IonAvatar,
} from "@ionic/react";
import { arrowBack, searchOutline } from "ionicons/icons";
import { Images } from "../../images";
import { GoCheckCircleFill } from "react-icons/go";
import { useSelector } from "react-redux";
import chatting from "../../api/chatting";
import { IoMdSend } from "react-icons/io";


const ForwardMessage = ({ isOpen, onClose, recentChats, myAcceptedFriends }) => {
  const [searchText, setSearchText] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const messageContent = useSelector((state) => state.messageContent);

  
  

  const filteredChats = myAcceptedFriends.filter((chat) =>
    chat.userName.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleSelectUser = (chatId) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(chatId)
        ? prevSelected.filter((id) => id !== chatId) 
        : [...prevSelected, chatId] 
    );
  };

  const handleSendForwardMessage = async () => {
    const response = await chatting.forwardMessages(
      messageContent.id,
      selectedUsers.join(","),
      messageContent.content,
    );
    console.log(response.data, "==>forwardTheMessages");
    onClose(true);
  };

 
  

  return (
    <IonModal isOpen={isOpen} onDidDismiss={onClose}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onClose}>
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>Forward to...</IonTitle>
          <IonButtons slot="end">
            <IonButton>
              <IonIcon icon={searchOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonSearchbar
          placeholder="Search"
          value={searchText}
          onIonInput={(e) => setSearchText(e.target.value)}
        />

        <IonList className="forward-list">
          <IonLabel className="ion-padding">Recent Chats</IonLabel>
          {filteredChats.map((chat, index) => (
            <IonItem
              key={index}
              onClick={() => handleSelectUser(chat.id)}
              className={selectedUsers.includes(chat.id) ? "selected-item" : ""}
            >
              <IonAvatar slot="start">
                <img src={Images.chatpic2} alt={chat.userName} />
              </IonAvatar>
              <IonLabel>
                <h2>{chat.userName}</h2>
                <p>{chat.lastMessage}</p>
              </IonLabel>
              {selectedUsers.includes(chat.id) && <GoCheckCircleFill  />}
            </IonItem>
          ))}
        </IonList>

        {/* Footer Section */}
        <div className="forward-footer">
          <div className="forward-footer-left">
            <IonLabel className="selected-count">
              {selectedUsers.length} selected
            </IonLabel>
          </div>
          <IonButton className="forward-button" onClick={handleSendForwardMessage}>
            <IoMdSend className="forward-message-icon" />
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ForwardMessage;
