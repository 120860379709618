import { IonCol, IonImg, IonRow } from "@ionic/react";
import React from "react";
import { Images } from "../images";
import { IoMdDownload } from "react-icons/io";
import { FaShareFromSquare } from "react-icons/fa6";

function Notification() {
  return (
    <div className="white-bg p-2 h-78vh overflow-auto">
      <div className="d-flex flex-column">
        <div className="password-clr font-16 fw-600 my-1">
          Where can I get some?
        </div>
        <div className="white-bg br-10 p-2 my-1 border-grey-not-not">
          <IonRow>
            <IonCol size="4">
              <div className="mx-1">
                <IonImg src={Images.Lake} className="w-100 h-10vh"></IonImg>
              </div>
            </IonCol>
            <IonCol
              size="8"
              className="d-flex flex-column justify-content-between"
            >
              <div className="font-16 grey-text">Prototype 02.pdf</div>
              <div className="d-flex flex-row align-items-end justify-content-between">
                <div className="font-12 grey-text">1 mb</div>
                <div>
                  <IoMdDownload className="font-20 mx-1" />
                  <FaShareFromSquare className="font-20 mx-1" />
                </div>
              </div>
            </IonCol>
          </IonRow>
        </div>
        <div className="w-100 d-flex justify-content-end grey-text font-14">
          18:22 pm , Nov 14 2023
        </div>
      </div>
      {Array.from({ length: 20 }, (_, index) => (
        <div key={index}>
          <div className="d-flex flex-column">
            <div className="password-clr font-16 fw-600 my-1">
              Where does it come from?
            </div>
            <div className="border-grey-not-not font-14 p-1 grey-text br-20 my-1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas
              accumsan lacus velsis.
            </div>
            <div className="w-100 d-flex justify-content-end grey-text font-14">
              18:22 pm , Nov 14 2023
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Notification;
