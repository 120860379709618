import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { Images } from "../../images";
import { IoLocation } from "react-icons/io5";
import { FaLongArrowAltRight } from "react-icons/fa";
import { IonButton, IonCol, IonRow } from "@ionic/react";
import { MdOutlineModeEdit } from "react-icons/md";
import AddAdminPopup from "../popup/AddAdminPopup";
import { MdBlockFlipped } from "react-icons/md";
import { api } from "../../api";
import BlockUser from "../popup/BlockUser";
import { IonModal } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";
import { CgUnblock } from "react-icons/cg";

function Admins() {
  const role = localStorage.getItem("role");
  const loginedRole = JSON.parse(localStorage.getItem("parent_details"));
  const [show, setShow] = useState(false);
  const [blockpop, setBlockpop] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [loginUser, setLoginUser] = useState("");
  const [gettedname, setgettedname] = useState([]);
  const [blockUserId, setblockUserId] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [addUpdatePopup, setAddUpdatePopup] = useState(false);

  const countTypes = (type) =>
    gettedname?.filter((user) => user?.role_code === type).length;

  const directorCount = countTypes("director");
  const managerCount = countTypes("manager");
  const customerCount = countTypes("user");
  const agentCount = countTypes("agent");

  const handleBlock = () => {
    setBlockpop(true);
  };
  const handleAdminClose = () => {
    setAddUpdatePopup(false);
  };

  const buttons = [
    {
      label: `All(${gettedname?.length})`,
      roles: ["director", "manager", "agent"],
    },
    { label: `Director(${directorCount})`, roles: ["director"] },
    { label: `Manager(${managerCount})`, roles: ["director"] },
    { label: `Agents(${agentCount})`, roles: ["director", "manager"] },
    {
      label: `Customers(${customerCount})`,
      roles: ["director", "manager", "agent"],
    },
  ];

  const getButtonsForRole = (role) => {
    return buttons
      .filter(
        (button) =>
          button.roles.includes(role) &&
          !button.label.toLowerCase().includes(role)
      )
      .map((button) => button.label);
  };
  const availableButtons = getButtonsForRole(role);

  const [activeButton, setActiveButton] = useState(0);
  const [userCreationdata, setUserCreationdata] = useState({});
  const [clintNumber, setClintnumber] = useState();
  const [updateShow, setUpdateShow] = useState({});

  const handleButton = (index) => {
    setActiveButton(index);
  };

  const handleEdit = () => {
    setEditProfile(true);
  };

  const handledata = (e) => {
    setUserCreationdata({
      ...userCreationdata,
      [e.target.name]: e.target.value,
    });
  };

  const filteredCustomers = gettedname?.filter((item) => {
    switch (role) {
      case "director":
        switch (activeButton) {
          case 0:
            return true;
          case 1:
            return ["manager"].includes(item?.role_code);
          case 2:
            return ["agent"].includes(item?.role_code);
          case 3:
            return ["user"].includes(item?.role_code);
          default:
            return false;
        }

      case "manager":
        switch (activeButton) {
          case 0:
            return ["agent", "user"].includes(item?.role_code);
          case 1:
            return ["agent"].includes(item?.role_code);
          case 2:
            return ["user"].includes(item?.role_code);
          default:
            return false;
        }

      case "agent":
        switch (activeButton) {
          case 0:
            return ["user"].includes(item?.role_code);
          case 1:
            return ["user"].includes(item?.role_code);
          default:
            return false;
        }

      default:
        return false;
    }
  });

  const handleShow = (userId) => {
    setShow(true);
    setblockUserId(userId);
  };

  const [showAdminPopup, setShowAdminPopup] = useState(false);
  const handleAddAdmin = () => {
    setShowAdminPopup(true);
  };

  const getCreatedUserDetails = () => {
    api
      .getCreatedRoles()
      .then((response) => {
        const userDetails = response?.data.map((user) => ({
          user_name: user?.user_name,
          parent_id: user?.parent_id,
          role_code: user?.role_code,
          location: user?.location,
          city: user?.city,
          state: user?.state,
          id: user?.id,
          is_blocked: user?.is_blocked,
        }));

        // const filteredUsers = userDetails?.filter(
        //   (user) => user?.is_blocked === 0
        // );

        setUpdateShow(userDetails);

        setgettedname(userDetails);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  useEffect(() => {
    getCreatedUserDetails();
    getMyLoginDetails();
  }, []);

  const filteredSearch = filteredCustomers?.filter(
    (item) =>
      item?.user_name &&
      item?.user_name.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const [isBlock, setIsBlock] = useState();
  console.log(isBlock);
  const handleBlockUser = async () => {
    try {
      if (blockUserId) {
        const response = await api.blockUser(blockUserId);
        setIsBlock(response?.data[0]?.is_blocked);
        console.log(response, "block admin");
        getCreatedUserDetails();
        setShow(false);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error blocking user:", error);
    }
  };

  const [userDetails, setUserDetails] = useState({
    user_name: "",
    password: "",
    location: "",
    city: "",
    state: "",
    country_code: "",
    phone: "",
    casino_comm: 0,
    other_tours_comm: 0,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleUpdatepopup = (userId) => {
    setAddUpdatePopup(true);
    setClintnumber(userId);
  };

  const handleUpdateuser = async () => {
    try {
      const response = await api.updateUser(clintNumber, userDetails);

      getCreatedUserDetails();
      setAddUpdatePopup(false);
    } catch (error) {
      console.error("Error blocking user:", error);
    }
  };

  const getMyLoginDetails = async () => {
    try {
      const response = await api.getCurrnetUserDetails();
      console.log(response.data, "===>getMyLoginDetails");
      setLoginUser(response.data);
    } catch (error) {
      // Handle error here
      console.error("Failed to fetch login details", error);
    }
  };

  console.log(loginUser, "===>loginUser");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="input-bg homecontent-sec pos-relative pb-20p">
      <div className="w-100 d-flex justify-content-end w-100 px-2">
        <div
          className="w-25 red-bg br-20 py-1 font-16 mt-2 fw-600 clr-white text-center"
          onClick={() => handleAddAdmin()}
        >
          + Add
        </div>
      </div>
      <div className="d-flex w-100 justify-content-center flex-column align-items-center border-bottom-grey py-2">
        <div>
          <img src={Images?.Pic1} />
        </div>
        <div className="font-16 fw-bold">
          {loginUser?.user_name}-{loginUser?.role?.code}
        </div>
        <div className="font-16 fw-bold grey-text d-flex align-items-center">
          <IoLocation className="font-24 grey-text" />
          {loginUser?.location}, {loginUser?.city}, {loginUser?.state},
          {loginUser?.country}
        </div>
        <div className="font-18 fw-600 grey-text my-1 d-flex align-items-center">
          {loginUser?.parent_id?.code} - {loginUser?.parent_id?.user_name}
          <FaLongArrowAltRight className="password-clr font-30 mx-1" />
          <span className="orange-clr ">
            {loginUser?.role?.code}-{loginUser?.user_name}
          </span>
        </div>
        <div className="w-100 justify-content-center d-flex align-items-center">
          <div className="w-55 border-grey-not white-bg password-clr br-20 my-2 font-20 py-2 w-50 text-center fw-600">
            Total Earn - 500000
          </div>
        </div>
      </div>

      <div className="p-2">
        <div className="white-bg w-100 d-flex align-items-center br-20 my-2 custom-box-shadow">
          <RiSearchLine className="font-24 w-20" />
          <input
            type="text"
            placeholder="Search By Name"
            className="all-none grey-text font-16 py-2 fw-600 w-80"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <IonRow>
          {availableButtons?.map((btn, index) => (
            <IonCol
              className={`font-14 mx-2 py-2 fw-600 br-20 text-center me-1 ${
                activeButton === index
                  ? "red-bg clr-white"
                  : "border-grey-not password-clr white-bg"
              }`}
              onClick={() => handleButton(index)}
            >
              {btn}
            </IonCol>
          ))}
        </IonRow>
        {filteredSearch?.map((item, colIndex) => (
          <div className="d-flex flex-column white-bg br-10 p-1 custom-box-shadow mt-2">
            <div className="font-14 fw-bold grey-text d-flex align-items-center py-1 border-bottom-grey">
              <IoLocation className="font-20 grey-text mr-1" />
              {item?.location}, {item?.city}, {item?.state} {item?.country}
            </div>
            <div className="d-flex flex-row justify-content-between w-100">
              <div className="d-flex flex-row align-items-center mt-2">
                <img
                  src={Images?.adminPic}
                  className="w-20"
                  style={{ height: "70px", width: "70px" }}
                  alt="Admin Pic"
                />
                <div className="font-20 fw-bold password-clr w-75 mx-1">
                  {item?.user_name}
                </div>
              </div>
              <div className="d-flex flex-row align-items-center mt-2">
                {item?.is_blocked === 0 ? (
                  <CgUnblock
                    className=" br-50 font-40 p-1 mx-1 clr-green"
                    onClick={() => handleShow(item?.id)}
                  />
                ) : (
                  <MdBlockFlipped
                    className=" br-50 font-40 p-1 mx-1 clr-red"
                    onClick={() => {
                      if (item?.is_blocked === 1) {
                        handleOpenModal();
                      } else {
                        handleShow(item?.id);
                      }
                    }}
                  />
                )}

                <MdOutlineModeEdit
                  className=" br-50 font-40 p-1 mx-1 clr-black"
                  onClick={() => handleUpdatepopup(item?.id)}
                />
              </div>
              <IonModal
                isOpen={isModalOpen}
                onDidDismiss={handleCloseModal}
                className="custom-modal"
              >
                <div className="white-bg br-10 p-2">
                <div className="d-flex flex-column align-items-center">
                  <div className="mx-2 my-2 text-center font-18">{`You don't have access to unblock this ${item?.user_name}`}</div>
                  <div
                    className="pink-bg flex-center py-2 w-30 mt-2 br-5 mb-2 clr-white"
                    onClick={handleCloseModal}
                  >
                    Close
                  </div>
                </div>
                </div>
              </IonModal>
            </div>
            <div class="d-flex flex-column justify-content-between  align-items-center p-2">
              <div className="d-flex flex-row align-items-center w-100 mt-1 justify-content-between">
                <div class="white-bg w-49 d-flex flex-column  justify-content-start p-2 border-grey-not br-20">
                  <div
                    className={`pointer mx-2 ${
                      item?.role_code === "user" ? "box-1" : "box-2"
                    }`}
                  ></div>
                  <div class="password-clr font-20 mt-2 fw-600">Customer</div>
                </div>
                <div class="white-bg w-49 d-flex flex-column  justify-content-start p-2 border-grey-not br-20">
                  <div
                    className={`pointer mx-2 ${
                      item?.role_code === "agent" ? "box-1" : "box-2"
                    }`}
                  ></div>
                  <div class="password-clr font-20 mt-2 fw-600">Agent</div>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center w-100 mt-1 justify-content-between">
                {" "}
                <div class="white-bg w-49 d-flex flex-column  justify-content-start p-2 border-grey-not br-20">
                  <div
                    className={`pointer mx-2 ${
                      item?.role_code === "manager" ? "box-1" : "box-2"
                    }`}
                  ></div>
                  <div class="password-clr font-20 mt-2 fw-600">Manager</div>
                </div>
                <div class="white-bg w-49 d-flex flex-column  justify-content-start p-2 border-grey-not br-20">
                  <div
                    className={`pointer mx-2 ${
                      item?.role_code === "director" ? "box-1" : "box-2"
                    }`}
                  ></div>
                  <div class="password-clr font-20 mt-2 fw-600">Director</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <IonModal isOpen={addUpdatePopup} className="custom-modal">
        <div className="h-85vh-popup">
          <div className="text-end" onClick={handleAdminClose}>
            <RxCross2 className="font-30" />
          </div>
          <div className="font-22 fw-bold text-center w-100 password-clr p-1">
            Add Adminstrators
          </div>
          <div className="p-1">
            <div className="d-flex flex-column my-2">
              <div className="password-clr fw-600 font-16 my-2">
                Name <span className="clr-red">*</span>
              </div>

              <input
                type="text"
                placeholder="Enter Name"
                className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
                name="user_name"
                onChange={handleChange}
              />
            </div>
            <div className="d-flex flex-column my-2">
              <div className="password-clr fw-600 font-16 my-2">
                Password <span className="clr-red">*</span>
              </div>
              <input
                type="password"
                placeholder="Enter Password"
                className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
                name="password"
                onChange={handleChange}
              />
            </div>
            <div className="d-flex flex-column my-2">
              <div className="password-clr fw-600 font-16 my-2">
                Location <span className="clr-red">*</span>
              </div>
              <input
                type="text"
                placeholder="Enter Location"
                className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
                name="location"
                onChange={handleChange}
              />
            </div>
            <div className="d-flex flex-column my-2">
              <div className="password-clr fw-600 font-16 my-2">
                City <span className="clr-red">*</span>
              </div>
              <select
                className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
                name="city"
                onChange={handleChange}
              >
                <option value="">Enter City</option>
                <option value="Hyderabad">Hyderabad</option>
                <option value="Dubai">Dubai</option>
              </select>
            </div>
            <div className="d-flex flex-column my-2">
              <div className="password-clr fw-600 font-16 my-2">
                State <span className="clr-red">*</span>
              </div>
              <select
                className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
                name="state"
                onChange={handleChange}
              >
                <option value="">Enter State</option>
                <option value="AP">AP</option>
                <option value="Telangana">Telangana</option>
              </select>
            </div>
            <div className="d-flex flex-column my-2">
              <div className="password-clr fw-600 font-16 my-2">
                Country <span className="clr-red">*</span>
              </div>
              <select
                className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
                name="country_code"
                onChange={handleChange}
              >
                <option value="">Enter Country</option>
                <option value="91">India</option>
                <option value="94">Sri Lanka</option>
              </select>
            </div>
            <div className="d-flex flex-column my-2">
              <div className="password-clr fw-600 font-16 my-2">
                Phone Number <span className="clr-red">*</span>
              </div>
              <input
                type="number"
                placeholder="Enter Phone Num"
                className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
                name="phone"
                onChange={handleChange}
              />
            </div>
            {role == "agent" || userCreationdata.role == "user" ? (
              ""
            ) : (
              <div className="d-flex flex-column my-2">
                <div className="password-clr fw-600 d-flex flex-row justify-content-between w-100 font-16 my-2">
                  <div>
                    Casino Comm <span className="clr-red">*</span>
                  </div>
                </div>

                <input
                  type="number"
                  placeholder="Enter Comm"
                  className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
                  name="casino_comm"
                  value={userCreationdata.casino_comm}
                  onChange={handledata}
                />
              </div>
            )}
            {role == "agent" || userCreationdata.role == "user" ? (
              ""
            ) : (
              <div className="d-flex flex-column my-2">
                <div className="password-clr fw-600 d-flex flex-row justify-content-between w-100 font-16 my-2">
                  <div>
                    Other Tours Comm <span className="clr-red">*</span>
                  </div>
                </div>
                <input
                  type="number"
                  placeholder="Enter Comm"
                  className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
                  name="other_tours_comm"
                  value={userCreationdata.other_tours_comm}
                  onChange={handledata}
                />
              </div>
            )}


            <div className="mt-3">
            <div
              className="red-bg br-20 clr-white fw-bold py-2 w-100 text-center"
              onClick={handleUpdateuser}
            >
              Update
            </div>
            </div>
          </div>
        </div>
      </IonModal>

      <AddAdminPopup
        showAdminPopup={showAdminPopup}
        setShowAdminPopup={setShowAdminPopup}
        getCreatedUserDetails={getCreatedUserDetails}
      />

      <BlockUser
        blockUser={show}
        setBlockUser={setShow}
        handleBlockUser={handleBlockUser}
      />
    </div>
  );
}

export default Admins;
