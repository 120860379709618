import { IonApp, setupIonicReact } from "@ionic/react";
import { BrowserRouter } from "react-router-dom";
import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "@splidejs/splide/css";
import Routing from "./routes/Routing";
import "./App.css";
setupIonicReact();

function App() {
  return (
    <IonApp>
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </IonApp>
  );
}

export default App;
