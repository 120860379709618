import React, { useState } from "react";
import { Images } from "../../images";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { api } from "../../api";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    user_name: "",
    password: "",
  });

  const handleLogin = (e) => {
    if (!formData?.user_name) {
      setErrorMessage("Username is required");
      return;
    }
    if (!formData?.password) {
      setErrorMessage("Password is required");
      return;
    }
    e.preventDefault();
    api
      .loginapi(formData)
      .then((response) => {
        if (response.status === true) {
          const roleCode = response.data.user.role_id.code;
          if (
            roleCode === "agent" ||
            roleCode === "director" ||
            roleCode === "manager"
          ) {
            localStorage.setItem("user_name", response.data.user?.user_name);
            localStorage.setItem("jwt_token", response.data.token);
            localStorage.setItem("myid", response.data.user.id);
            localStorage.setItem("role", response.data.user.role_id.code);
            localStorage.setItem("myid", response.data.user.id);
            localStorage.setItem(
              "parent_details",
              JSON.stringify(response.data.user)
            );
            localStorage.setItem("user_id", response.data.user.id);
            navigate("/home");
          } else {
            setErrorMessage("Something Went Wrong");
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setErrorMessage("Invalid username or password");
          } else if (error.response.data?.message?.includes("user_name")) {
            setErrorMessage("Username is incorrect");
          } else if (error.response.data?.message?.includes("password")) {
            setErrorMessage("Password is incorrect");
          } else {
            setErrorMessage("An unexpected error occurred");
          }
        } else {
          setErrorMessage("Network error or server is down");
        }
        console.error("Error in login:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="h-100vh d-flex justify-content-between align-items-center flex-column">
      <div className="d-flex flex-column w-100 justify-content-center align-items-center">
        <div className="w-100 d-flex justify-content-center p-4">
          <img src={Images.LoginLogo} className="logo-img" alt="Login Logo" />
        </div>
        <h1 className="w-100 text-center fw-bold">Login</h1>
        <span className="font-16 fw-600 grey-text">
          Secure Access to your Agent Account
        </span>
        <div className="w-100 py-2 px-4">
          <div className="d-flex flex-column my-1">
            <div className="my-1 password-clr fw-bold font-16">UserName</div>
            <input
              type="text"
              name="user_name"
              placeholder="Enter User Name"
              className="input-bg bg-none grey-text my-1 p-2 br-10 fw-600"
              onChange={handleChange}
            />
          </div>
          <div className="d-flex flex-column my-1">
            <div className="my-1 password-clr fw-bold font-16">Password</div>
            <div className="input-bg d-flex flex-row align-items-center p-2 br-10">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter Password"
                name="password"
                className="all-none grey-text fw-600 w-80"
                onChange={handleChange}
              />
              {showPassword ? (
                <IoMdEyeOff
                  className="font-24 grey-text w-20 text-center"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <IoMdEye
                  className="font-24 grey-text w-20 text-center"
                  onClick={togglePasswordVisibility}
                />
              )}
            </div>
          </div>
          {errorMessage && <div className="clr-red">{errorMessage}</div>}
          <div
            className="font-18 fw-bold my-2 password-clr"
            onClick={() => navigate("/otp")}
            style={{ cursor: "pointer" }}
          >
            <u>Forgot Password?</u>
          </div>
          <button
            type="submit"
            onClick={(e) => handleLogin(e)}
            className="w-100 red-bg clr-white fw-600 py-3 mt-4 br-20 font-18 text-center"
          >
            Login
          </button>
        </div>
      </div>
      <img src={Images.Loginbg} className="w-100" alt="Background" />
    </div>
  );
}

export default Login;
