import React, { useEffect, useState } from "react";
import { Images } from "../../images";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router";
import { IoArrowBackCircle } from "react-icons/io5";
import chatting from "../../api/chatting";
import { useDispatch } from "react-redux";
import { setSelectedUser } from "../../redux/action";

function Chat({ isChatPageActive }) {
  const [creationDetails, setCreationDetails] = useState([]);
  const [blockedUser, setBlockedUser] = useState(false);
  const navigate = useNavigate();
  const myId = localStorage.getItem("myid");

  const [searchTerm, setSearchTerm] = useState("");
  const filteredChatItems = creationDetails?.filter((chat) =>
    chat?.userName?.toLowerCase().includes(searchTerm?.toLowerCase())
  );
  const hasBlockedUser = filteredChatItems.some(
    (item) => item.blocked === true
  );
  console.log(hasBlockedUser, "==>hasBlockedUser");

  const dispatch = useDispatch();
  const handleClick = (id, userName,block) => {
    console.log(id, userName);

    dispatch(setSelectedUser({ userName: userName, userId: id ,block:block}));
    navigate(`${id}`);
  };

  const fetchDataOfUsers = async () => {
    try {
      chatting?.fetchYourCreations(myId).then((response) => {
        setCreationDetails(response?.data);
      });
    } catch (err) {
      // Handle errors
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    fetchDataOfUsers();
  }, []);

  return (
    <div
      className={
        isChatPageActive
          ? "input-bg px-2 homecontent-sec"
          : "input-bg px-2 homecontent-sec"
      }
    >
      <div>
        <div className="font-24 my-2 fw-600 d-flex align-items-center">
          <IoArrowBackCircle
            className="clr-pink font-40"
            onClick={() => navigate("/home")}
          />
          <span className="mx-1">Chat Page</span>
        </div>
        <div className="white-bg w-100 border-grey px-1 py-2 d-flex align-items-center br-10">
          <input
            type="text"
            placeholder="Search Users"
            className=" font-12 grey-text w-90 all-none fw-600"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FaSearch className="large-font grey-text w-10" />
        </div>
        <div className="d-flex flex-column white-bg p-1 mt-1 h-85vh overflow-auto">
          {filteredChatItems?.map((chat, index) => (
            <div
              key={index}
              className="d-flex flex-row align-items-center py-1 border-bottom-grey"
              onClick={() => handleClick(chat?.id, chat?.userName,chat?.blocked)}
            >
              <div className="w-20">
                <img
                  src={Images.chatpic1}
                  className="chat-profile"
                  alt="Profile"
                />
              </div>
              <div className="d-flex flex-column w-80  font-12 ">
                <div className="d-flex flex-row justify-content-between ">
                  <div className="font-18 password-clr fw-600 small-font">
                    {chat.userName}
                  </div>

                  <div className="grey-text">{chat.time}</div>
                </div>
                <div className="grey-text  font-12 mt-2px chat-item">
                  {chat.lastmessage}
                  <span className="font-12">
                    {chat.blocked ? "Blocked" : "Tap To Chat"}
                  </span>
                </div>
                {chat.isTyping && (
                  <div className=" font-12 clr-green mt-2px white-space">
                    {chat.name} is typing..
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Chat;
