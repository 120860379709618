import axios from "axios";
import environment from "../environments/environments.ts";


export default {
  getTourDetails: async () => {
    try {
      const response = await axios.get(`${environment.baseUrl}/get-tour`);
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching category:",
        error.response?.data || error.message
      );
      throw error;
    }
  },
};
