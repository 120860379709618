import React, { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";
import { LuCalendarDays } from "react-icons/lu";
import { Images } from "../../images";
import { FaRegCalendarCheck } from "react-icons/fa";
import { IoLocation } from "react-icons/io5";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FiEye } from "react-icons/fi";
import { HiDownload, HiOutlineArrowNarrowRight } from "react-icons/hi";
import { FaShareSquare } from "react-icons/fa";
import {
  getAllTours,
  getApprovedTours,
  getItineraryTours,
  getOngoingTours,
  getRejectedTours,
} from "../../api-folder/apiMethods";
import moment from "moment";

const ToursOne = () => {
  const [checkedState, setCheckedState] = useState({
    itinerary: true,
    approved: false,
    pending: false,
    rejected: false,
  });

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCheckedState({
      itinerary: id === "itinerary-checkbox" ? checked : false,
      approved: id === "approved-checkbox" ? checked : false,
      pending: id === "pending-checkbox" ? checked : false,
      rejected: id === "rejected-checkbox" ? checked : false,
    });
  };

  //travel tickets
  const [itineraryTickets, setItineraryTickets] = useState([]);
  console.log(itineraryTickets, "itineraryTickets");

  const [approvedTickets, setApprovedTickets] = useState([]);
  console.log(approvedTickets, "approvedTickets");
  const [pendingTickets, setPendingTickets] = useState([]);
  const [rejectedTickets, setRejectedTickets] = useState([]);

  const filteredTrips = (() => {
    if (checkedState.itinerary) return itineraryTickets;
    if (checkedState.approved) return approvedTickets;
    if (checkedState.pending) return pendingTickets;
    if (checkedState.rejected) return rejectedTickets;
    return [];
  })();

  const itineryTickets = () => {
    getAllTours()
      .then((response) => {
        if (response.status === true) {
          console.log(response, "itineary tickets");
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    itineryTickets();
  }, []);

  const itinearyData = () => {
    getItineraryTours(checkedState.itinerary)
      .then((response) => {
        if (response.status === true) {
          setItineraryTickets(response?.data);
          console.log("itinerryyyyy", response);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    itinearyData();
  }, [checkedState.itinerary]);

  const approvedData = () => {
    getApprovedTours(checkedState.approved)
      .then((response) => {
        if (response.status === true) {
          setApprovedTickets(response?.data);
          console.log("approvedddd", response);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    approvedData();
  }, [checkedState.approved]);

  const ongoingData = () => {
    getOngoingTours(checkedState.pending)
      .then((response) => {
        if (response.status === true) {
          setPendingTickets(response?.data);
          console.log("ongoingggg", response);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    ongoingData();
  }, [checkedState.pending]);

  const rejectedData = () => {
    getRejectedTours(checkedState.rejected)
      .then((response) => {
        if (response.status === true) {
          setRejectedTickets(response?.data);
          console.log("rejecteddddd", response);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    rejectedData();
  }, [checkedState.rejected]);

  return (
    <div className="input-bg homecontent-sec pos-relative pb-20p">
      <div className="px-2 py-4 border-bottom-grey">
        <div className="font-18 password-clr fw-bold">Travel Details</div>
        <div className="white-bg w-100 d-flex align-items-center br-20 my-2">
          <RiSearchLine className="font-24 w-20" />
          <input
            type="text"
            placeholder="Search By Name"
            className="all-none grey-text font-16 py-2 fw-600 w-80"
          />
        </div>
        <div className="white-bg w-100 d-flex align-items-center br-20 my-2">
          <LuCalendarDays className="font-24 w-20" />
          <input
            type="date"
            placeholder="Search By Date"
            className="all-none grey-text font-16 py-2 fw-600 w-75"
          />
        </div>   
        <div className="red-bg clr-white text-center w-100 fw-600 py-2 mt-4 br-20">
          Submit
        </div>
      </div>

      {/* Checkbox Filters */}
      <div className="d-flex flex-row justify-content-between align-items-center p-2">
        {["itinerary", "approved", "pending", "rejected"].map((type) => (
          <div
            key={type}
            className={`white-bg d-flex flex-column w-30 justify-content-start p-2 border-grey-not br-20 ${
              checkedState[type] ? "checked" : ""
            }`}
          >
            <label className="checkbox-container">
              <input
                type="checkbox"
                id={`${type}-checkbox`}
                checked={checkedState[type]}
                onChange={handleCheckboxChange}
              />
              <span className="checkmark"></span>
            </label>
            <div className="password-clr font-18 mt-2 fw-600">
              {type.charAt(0).toUpperCase(1) + type.slice(1)}
            </div>
          </div>
        ))}
      </div>

      {/* Display Filtered Trips */}
      {filteredTrips.length > 0 ? (
        filteredTrips.map((trip) => (
          <div key={trip.id} className="p-2">
            <div className="d-flex flex-column white-bg br-10 p-2">
              <div className="d-flex flex-row align-items-center justify-content-between w-100 border-bottom-grey py-2">
                <div className="password-clr font-18 fw-bold">
                  Trip: {trip?.tour_title}
                </div>
                <div className="font-14 fw-600 align-items-center d-flex">
                  <LuCalendarDays className="font-18 mx-1" />
                  {moment(trip?.schedule_from).format("Do MMMM,YYYY")}
                </div>
              </div>

              <div className="d-flex flex-column w-100">
                <div className="d-flex flex-row align-items-center w-100 mt-1">
                  <img
                    src={trip?.image || Images.Boy}
                    className="w-20"
                    style={{ height: "70px", width: "70px" }}
                    alt="Profile"
                  />
                  <div className="font-20 fw-bold password-clr w-75 mx-1">
                    {trip.user_name}
                  </div>
                  {checkedState.pending && (
                    <div className="d-flex justify-content-center pt-3 ">
                      <span
                        className="px-3 py-2 rounded-pill fw-500 text-hash medium-font pointer border"
                        // onClick={() => handleCancel(trip?.bookedFor_id)}
                      >
                        Cancel
                      </span>
                    </div>
                  )}
                  {checkedState.rejected && (
                    <div className="d-flex justify-content-center pt-3 ">
                      <span
                        className="px-3 py-2 rounded-pill fw-500 text-hash medium-font pointer border"
                        // onClick={() => handleCancel(trip?.bookedFor_id)}
                      >
                        Reject
                      </span>
                    </div>
                  )}
                </div>
                <div className="d-flex flex-row align-items-center w-100 mt-1">
                  <FaRegCalendarCheck className="grey-text font-20" />
                  <div className="font-18 fw-bold password-clr w-75 mx-1">
                    Book By: {trip.booking_id}
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center w-100 mt-1">
                  <IoLocation className="grey-text font-20" />
                  <div className="font-16 fw-600 grey-text mx-1">
                    {trip.location}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="font-small text-black d-flex flex-wrap ">
                    {trip?.parents?.map((parent, index) => (
                      <div
                        key={index}
                        className="d-flex flex-between white-space "
                      >
                        <span>
                          {parent?.user_name}-{parent?.role}
                        </span>
                        {index < trip?.parents?.length - 1 && (
                          <span className="mt-1">
                            <HiOutlineArrowNarrowRight className="mx-1 fw-bold clr-pink" />
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                {checkedState.itinerary && (
                  <div>
                    <div className="fw-bold font-16">
                      Travel Tickets
                      <div>
                        {trip?.travel_details?.map((trip, index) => (
                          <div className="d-flex flex-row w-100 border-grey-not my-1 br-10 p-2 h-10vh align-items-center">
                            <img
                              src={
                                trip?.path.replace(/"/g, "") ||
                                Images.screenChat
                              }
                              className="hotel-image"
                              alt="travel"
                            />
                            <div className="w-75 line-height">
                              <div className="password-clr font-18 fw-600 password-clr">
                                {trip?.fileName}
                              </div>
                              <div className="d-flex flex-row align-items-center w-100 ms-1 mt-1 justify-content-between">
                                <div className="grey-text font-18">
                                  {trip?.fileSize}
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                  <FiEye className="font-30 mx-1 input-bg p-1 br-50" />
                                  <HiDownload className="font-30 mx-1 input-bg p-1 br-50" />
                                  <FaShareSquare className="font-30 mx-1 input-bg p-1 br-50" />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="fw-bold font-16">
                      Hotel Tickets
                      <div>
                        {trip?.hotel_details?.map((trip, index) => (
                          <div className="d-flex flex-row w-100 border-grey-not my-1 br-10 p-2 h-10vh align-items-center">
                            <img
                              src={
                                trip?.path.replace(/"/g, "") ||
                                Images.screenChat
                              }
                              className="hotel-image"
                              alt="travel"
                            />
                            <div className="w-75 line-height">
                              <div className="password-clr font-18 fw-600 password-clr">
                                {trip?.fileName}
                              </div>
                              <div className="d-flex flex-row align-items-center w-100 ms-1 mt-1 justify-content-between">
                                <div className="grey-text font-18">
                                  {trip?.fileSize}
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                  <FiEye className="font-30 mx-1 input-bg p-1 br-50" />
                                  <HiDownload className="font-30 mx-1 input-bg p-1 br-50" />
                                  <FaShareSquare className="font-30 mx-1 input-bg p-1 br-50" />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="fw-bold font-16">
                      Guidance Tickets
                      <div>
                        {trip?.guidance_details?.map((trip, index) => (
                          <div className="d-flex flex-row w-100 border-grey-not my-1 br-10 p-2 h-10vh align-items-center">
                            <img
                              src={
                                trip?.path.replace(/"/g, "") ||
                                Images.screenChat
                              }
                              className="hotel-image"
                              alt="travel"
                            />
                            <div className="w-75 line-height">
                              <div className="password-clr font-18 fw-600 password-clr">
                                {trip?.fileName}
                              </div>
                              <div className="d-flex flex-row align-items-center w-100 ms-1 mt-1 justify-content-between">
                                <div className="grey-text font-18">
                                  {trip?.fileSize}
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                  <FiEye className="font-30 mx-1 input-bg p-1 br-50" />
                                  <HiDownload className="font-30 mx-1 input-bg p-1 br-50" />
                                  <FaShareSquare className="font-30 mx-1 input-bg p-1 br-50" />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <div className="d-flex flex-row align-items-center justify-content-between w-100 mt-2">
                  <div className="grey-text font-16 fw-600">
                    {moment(trip?.schedule_from).format("dddd, hh:mm A")}
                  </div>
                  <div className="font-16 fw-600 grey-text">
                    {moment(trip?.schedule_from).format("Do MMMM,YYYY")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="p-2">No trips available for the selected filter.</div>
      )}
    </div>
  );
};
export default ToursOne;
