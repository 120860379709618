import React, { useEffect, useState } from "react";
import { Images } from "../../images";
import { IoLocation } from "react-icons/io5";
import { IoMdEye } from "react-icons/io";
import { PiAirplaneTakeoffLight } from "react-icons/pi";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { PiHandCoinsLight } from "react-icons/pi";
import { getTravelReports } from "../../api-folder/apiMethods";
import { getDownlineTravelReportsById } from "../../api-folder/apiMethods";
import { useDispatch, useSelector } from "react-redux";
import { setTravelReportsData } from "../../redux/action";
import CommonTable from "../../components/CommonTable";
import {
  getTravelReportDetailsById,
  getTravelReportDetailsSummaryById,
} from "../../api-folder/apiMethods";
import { FaLongArrowAltRight } from "react-icons/fa";

function Tour() {
  const dispatch = useDispatch();

  // get travel reports
  const [travelReports, setTravelReports] = useState([]);
  console.log(travelReports, "==travel reports");

  const fetchTravelReports = () => {
    getTravelReports({})
      .then((response) => {
        console.log(response, " travel reports response");
        if (response?.status === true) {
          setTravelReports(response.data);
          dispatch(setTravelReportsData(response.data));
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    fetchTravelReports();
  }, [dispatch]);

  const userDetails = useSelector((state) => state.userDetails);

  const [data, setData] = useState([]);
  console.log(data, "data==>>s");

  const fetchTravelreportsById = () => {
    getDownlineTravelReportsById(userDetails?.id)
      .then((response) => {
        if (response.status === true) {
          console.log(response, "response?");
          setData(response?.data?.downlinesWithDetails, "setDatta===>>");
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    fetchTravelreportsById();
  }, [userDetails?.id]);

  const rowData = data?.map((item) => ({
    name: item?.user_name,
    details:
      item?.role_code === "user"
        ? item?.role_code
        : `(C-${userDetails?.casino_comm} & OT-${userDetails?.other_tours_comm})`,
    customer: item?.totalCustomers,
    completedTrips: item?.completedTrips,
    totalBooking: item?.totalBookings,
    col2Data: {
      firstValue: 120000,
      downline: "Riya Shah",
      secondValue: 220000,
    },
  }));

  const user_name = localStorage?.getItem("user_name");
  const userRole = localStorage?.getItem("role");
  const role_id = localStorage?.getItem("myid");
  const userDetailsData = useSelector((state) => state?.userDetailsData);
  const [travelReportsSummary, setTravelReportsSummary] = useState();
  const [travelReportsData, setTravelReportsData] = useState();
  const [travelReportsTotal, setTravelReportsTotal] = useState();
  const [selectedUserId, setSelectedUserId] = useState(role_id);
  const [manager, setManager] = useState({
    name: "",
    role: "",
  });
  const [agent, setAgent] = useState({
    name: "",
    role: "",
  });
  const handleSelectedDirector = () => {
    setSelectedUserId(role_id);
    setManager({
      name: "",
      role: "",
    });
    setAgent({
      name: "",
      role: "",
    });
  };
  const handleSelectedManager = () => {
    setSelectedUserId(manager?.user_id);
    setAgent({
      name: "",
      role: "",
    });
  };

  const handleSelectedUser = (userId, userName, role) => {
    setSelectedUserId(Number(userId));
    if (role === "manager") {
      setManager({
        name: userName,
        role: role,
      });
    } else if (role === "agent") {
      setAgent({
        name: userName,
        role: role,
      });
    }
  };

  useEffect(() => {
    getTravelReportDetailsSummaryById(selectedUserId)
      .then((response) => {
        if (response?.status === true) {
          setTravelReportsSummary(response?.data);
        }
      })
      .catch((error) => console.log(error?.message));

    getTravelReportDetailsById(selectedUserId)
      .then((response) => {
        if (response?.status === true) {
          setTravelReportsData(response?.data?.downlinesWithDetails || []);
          setTravelReportsTotal(response?.data);
        }
      })
      .catch((error) => console.log(error?.message));
  }, [selectedUserId]);

  const TRAVEL_REPORT_COLUMNS = [
    { key: "name", title: "Name" },
    { key: "comm", title: "Comm" },
  ];
  const TRAVEL_REPORT_DATA = travelReportsData?.map((item) => ({
    name: (
      <div className="password-clr font-16 fw-600 d-flex flex-column">
        <div>
          {item?.user_name}({item?.role_code})
        </div>
        <div className="font-12 grey-text">(C - 0% & OT - 0%)</div>
        <div>Customer: {item.totalCustomers}</div>
        <div>Completed Trips: {item.completedTrips}</div>
        <div>Total Booking: {item.totalBookings}</div>
      </div>
    ),
    comm: (
      <div className="flex-between">
        <div className="password-clr line-height font-16 fw-600 d-flex flex-column">
          <div>Casino Comm: {item.casino_comm}</div>
          <div>Other Comm: {item.casino_comm}</div>
        </div>
        <div>
          {item?.role_code === "user" ? (
            "- -"
          ) : (
            <IoMdEye
              className="font-30"
              onClick={() =>
                handleSelectedUser(
                  item?.user_id,
                  item?.user_name,
                  item?.role_code
                )
              }
            />
          )}
        </div>
      </div>
    ),
  }));
  const TRAVEL_REPORTS_FOOTER = [
    {
      header: (
        <div className="text-start">
          <div>Tot Customers: {travelReportsTotal?.total_customers}</div>
          <div>
            Tot Compl Trips: {travelReportsTotal?.total_completed_trips}
          </div>
          <div>Tot Bookimgs: {travelReportsTotal?.total_bookings}</div>
        </div>
      ),
    },
    {
      header: (
        <div className="text-start">
          <div>Tot Casino Comm: 00</div>
          <div>Tot Other Comm: 00</div>
        </div>
      ),
    },
  ];

  // const formattedData = rowData?.map((item) => ({
  //   name_role: (

  return (
    <div className="input-bg homecontent-sec pos-relative pb-20p">
      <div className="p-2">
        <div className="d-flex w-100 justify-content-center flex-column align-items-center border-bottom-grey py-2">
          <div>
            <img src={Images.Pic1} alt="" />
          </div>
          <div className="d-flex flex-wrap">
            <div className="font-16 fw-bold" onClick={handleSelectedDirector}>
              {user_name} - {userRole}
            </div>
            {manager?.name !== "" && (
              <div
                className="font-16 fw-bold d-flex"
                onClick={handleSelectedManager}
              >
                <FaLongArrowAltRight className="font-24 text-orange mx-1" />
                {manager?.name}({manager?.role})
              </div>
            )}
            {agent?.name !== "" && (
              <div
                className="font-16 fw-bold d-flex"
                onClick={handleSelectedManager}
              >
                <FaLongArrowAltRight className="font-24 text-orange mx-1" />
                {agent?.name}({agent?.role})
              </div>
            )}
          </div>
          <div className="font-16 fw-bold grey-text d-flex align-items-center">
            <IoLocation className="font-24 grey-text" />
            {userDetailsData?.location}, {userDetailsData?.city},
            {userDetailsData?.state}, {userDetailsData?.country}
          </div>
        </div>
        <div className="py-2">
          <h3 className="fw-bold clr-black">Activity Summary</h3>
          <div className="white-bg br-10 p-2 d-flex flex-row align-items-center my-1">
            <div className="br-50 p-2 w-16 d-flex justify-conten-center pink-bg mr-1">
              <PiAirplaneTakeoffLight className="font-40 clr-white" />
            </div>
            <div className="w-90 d-flex flex-column line-height">
              <div className="font-24 fw-bold ">
                {travelReportsSummary?.totalCount}
              </div>
              <div className="password-clr font-16 fw-bold">
                Total Travel Planned
              </div>
            </div>
          </div>
          <div className="white-bg br-10 p-2 d-flex flex-row align-items-center my-1">
            <div className="br-50 p-2 w-16 d-flex justify-conten-center pink-bg mr-1">
              <HiOutlineUserGroup className="font-40  clr-white" />
            </div>
            <div className="w-90 d-flex flex-column justify-content-start line-height">
              <div className="d-flex flex-row w-100 justify-content-between">
                <div className="d-flex flex-column text-center">
                  <div className="font-12 fw-600 font-12 green-bg password-clr text-center br-10 px-2">
                    Admin
                  </div>
                  <div className="font-24 fw-bold">
                    {travelReportsSummary?.downlinesCount?.marcketingTeamCount}
                  </div>
                </div>
                <div className="d-flex flex-column text-center">
                  <div className="font-12 fw-600 font-12 sky-bg password-clr text-center br-10 px-2">
                    Customer
                  </div>
                  <div className="font-24 fw-bold">
                    {travelReportsSummary?.downlinesCount?.usersCount}
                  </div>
                </div>{" "}
              </div>
              <div className="password-clr font-16 fw-bold">
                Total Team Members
              </div>
            </div>
          </div>
          <div className="white-bg br-10 p-2 d-flex flex-row align-items-center my-1">
            <div className="br-50 p-2 w-16 d-flex justify-conten-center pink-bg mr-1">
              <HiOutlineUserGroup className="font-40 clr-white" />
            </div>
            <div className="w-90 d-flex flex-column line-height">
              <div className="font-24 fw-bold ">
                {travelReportsSummary?.bookingsCount}
              </div>
              <div className="password-clr font-16 fw-bold">
                Total Travel Customer
              </div>
            </div>
          </div>
          <div className="white-bg br-10 p-2 d-flex flex-row align-items-center my-1">
            <div className="br-50 p-2 w-16 d-flex justify-conten-center pink-bg mr-1">
              <PiHandCoinsLight className="font-40 clr-white" />
            </div>
            <div className="w-90 d-flex flex-column line-height">
              <div className="font-24 fw-bold ">
                {travelReportsSummary?.totalEarnings}
              </div>
              <div className="password-clr font-16 fw-bold">Total Earning</div>
            </div>
          </div>
        </div>
        <div className="orange-clr font-20 fw-bold">
          {manager.name
            ? `${manager.name} (${manager.role})`
            : agent.name
            ? `${agent.name} (${agent.role})`
            : `${user_name} (${userRole})`}
        </div>
        <div className="white-bg py-2 w-100 d-flex flex-row br-20 px-2 align-items-center mt-2">
          <select className="w-100 all-none font-16 fw-bold">
            <option>Select Customer</option>
          </select>
        </div>
        <div className="white-bg py-2 w-100 d-flex flex-row br-20 px-2 align-items-center mt-2">
          <select className="w-100 all-none font-16 fw-bold">
            <option>Select Tour</option>
          </select>
        </div>
      </div>
      <CommonTable
        columns={TRAVEL_REPORT_COLUMNS}
        data={TRAVEL_REPORT_DATA}
        footer={TRAVEL_REPORTS_FOOTER}
        itemsPerPage={5}
      />
    </div>
  );
}

export default Tour;
