import React from "react";
import { IonPage } from "@ionic/react";
import { Route, Routes, useLocation } from "react-router";
import Login from "../pages/login/Login";
import Otp from "../pages/login/Otp";
import HomePage from "../pages/homepage/HomePage";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CasinoTour from "../pages/tour/CasinoTour";
import Tour from "../pages/tour/Tour";
import Wallet from "../pages/wallet/Wallet";
import Admins from "../pages/admins/Admins";
import ToursOne from "../pages/tour/ToursOne";
import Memories from "../pages/memories/Memories";
import Message from "../components/Message";
import Chat from "../pages/chat/Chat";
import ChatScreen from "../pages/chat/Chatscreen";
import PrivateRoutes from "./PrivateRoutes";
import Logout from "../pages/login/Logout";
import LikesPage from "../pages/likescomment/LikesPage";
import ChatHeader from "../pages/chat/ChatHeader";
import ChatFooter from "../pages/chat/ChatFooter";
import ForgetPassword from "../pages/popup/ForgotPassword";
import ToureDescriptionPage from "../pages/tour/ToureDescriptionPage";
import EditProfile from './../pages/homepage/EditProfile';
import { IoLocation } from 'react-icons/io5';

function Routing() {
  const location = useLocation();
  const isLoginOrOtpPage = ["/", "/otp"].includes(location.pathname);
  const isChatScreenActive = location.pathname.startsWith("/chat/");
  const isLikesPageActive = location.pathname.startsWith("/likes");
  const isEditProfile =location.pathname.startsWith("/edit-profile");
  // const path = location.pathname;
  // const isLoginOrOtpPage = ["/", "/otp"].includes(path);
  // const isChatScreenActive = path.startsWith("/chat/");
  // const isLikesPageActive = path === "/likes";
  const showHeaderFooter = !isLoginOrOtpPage && !isLikesPageActive && !isEditProfile;
  const showHeader = !isLoginOrOtpPage && !isEditProfile;
  const showFooter = !isLikesPageActive && !isChatScreenActive && !isLoginOrOtpPage;
 

  return (
    <div>
      {isChatScreenActive ? <ChatHeader /> : showHeader && <Header />}
      <IonPage
        className={
          ["/chat", "/likes/:id", "/edit-profile"].includes(location.pathname) ? "h-100vh" : "mt-8vh"
        }
      >
        <Routes>
          <Route path="/" element={<Login />} exact />
          <Route path="/otp" element={<Otp />} exact />
          {/* <Route path="/forget-pswd" element={<ForgetPassword />} exact /> */}

          <Route element={<PrivateRoutes />}>
            <Route path="/home" element={<HomePage />} exact />
            <Route path="/message" element={<Message />} exact />
            <Route path="/tours/:tourCategory" element={<CasinoTour />} exact />
            <Route
              path="/tour/:tourCategory/:tourId"
              element={<ToureDescriptionPage />}
              exact
            />
            <Route path="/reports" element={<Tour />} exact />
            <Route path="/wallet" element={<Wallet />} exact />
            <Route path="/admins" element={<Admins />} exact />
            <Route path="/tours" element={<ToursOne />} exact />
            <Route path="/memories" element={<Memories />} exact />
            <Route path="/likes/:id" element={<LikesPage />} exact />
            <Route path="/logout" element={<Logout />} exact />
            <Route path="/chat" element={<Chat />} exact />
            <Route path="/chat/:id" element={<ChatScreen />} exact />
            <Route path="/edit-profile" element={<EditProfile/>} exact/>
          </Route>
        </Routes>
      </IonPage>
      {isChatScreenActive ? <ChatFooter /> : showHeaderFooter && <Footer />}
    </div>
  );
}

export default Routing;
