import { IonModal } from "@ionic/react";
import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";

function DeletePopup({
  showConfirmationPopup,
  setShowConfirmationPopup,
  handleConfirm,
  description,
}) {
  const [active, setActive] = useState("yes");

  const buttons = [
    { id: "yes", label: "Yes" },
    { id: "reject", label: "No" },
  ];

  return (
    <IonModal
      isOpen={showConfirmationPopup}
      onDidDismiss={() => setShowConfirmationPopup(false)}
      centered
      className="custom-modal"
    >
      <div className="white-bg br-10 p-2">
      <div className="mx-1 d-flex flex-column flex-center align-items-center">
        <div className="w-100 text-end">
          <RxCross2
            className="clr-black mx-2 my-2 font-medium cursor-pointer"
            onClick={() => setShowConfirmationPopup(false)}
          />
        </div>

        <div className="w-100 d-flex flex-column justify-content-center align-items-center">
          <div className="d-flex w-85 flex-column  password-clr text-center my-1 large-font fw-600">
            {description}
          </div>
          <div className="d-flex flex-between my-1 w-100 px-3">
            <div
              className="br-20 fw-600 medium-font w-40 text-center px-4 py-2 m-2 pink-bg clr-white  cursor-pointer"
              onClick={handleConfirm}
            >
              Yes
            </div>
            <div className="br-20 fw-600 medium-font w-40 border-grey text-center px-4 py-2 m-2  white-bg grey-text border-greyy cursor-pointer" 
           onClick={()=>setShowConfirmationPopup(false)}
           >
              No
            </div>
          </div>
        </div>
      </div>
      </div>
    </IonModal>
  );
}

export default DeletePopup;
