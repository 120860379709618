import { IonModal } from "@ionic/react";
import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { api } from "../../api";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

function AddAdminPopup({
  showAdminPopup,
  setShowAdminPopup,
  getCreatedUserDetails,
}) {
  const handleAdminClose = () => {
    setShowAdminPopup(false);
  };

  const [userCreationdata, setUserCreationdata] = useState({
    role: "",

    user_name: "",
    password: "",
    city: "",
    state: "",
    country_code: "",
    phone: "",
    casino_comm: "",
    other_tours_comm: "",
  });

  const handledata = (e) => {
    setUserCreationdata({
      ...userCreationdata,
      [e.target.name]: e.target.value,
    });
  };

  const handleRoles = () => {
    api
      .createRole({
        role: userCreationdata?.role,
        user_name: userCreationdata.user_name,
        password: userCreationdata.password,
        city: userCreationdata.city,
        state: userCreationdata.state,
        country_code: userCreationdata.country_code,
        phone: userCreationdata.phone,
        casino_comm: userCreationdata?.casino_comm || 0,
        other_tours_comm: userCreationdata?.other_tours_comm || 0,
      })
      .then((response) => {
        setUserCreationdata("");
        setShowAdminPopup(false);
        getCreatedUserDetails();
      })

      .catch((error) => {
        console.error("Error in creation:", error);
      });
  };
  const role = localStorage.getItem("role");
  const renderOptions = () => {
    switch (role) {
      case "director":
        return (
          <>
            <option value="manager">Manager</option>
            <option value="agent">Agent</option>
            <option value="user">Customer</option>
          </>
        );
      case "manager":
        return (
          <>
            <option value="agent">Agent</option>
            <option value="user">Customer</option>
          </>
        );
      case "agent":
        return <option value="user">Customer</option>;

      default:
        return null;
        
    }
  };

  return (
    <IonModal isOpen={showAdminPopup} className="custom-modal">
      <div className="h-95vh-popup">
        <div className="text-end my-1" onClick={handleAdminClose}>
          <RxCross2 className="font-30" />
        </div>
        <div className="font-22 fw-bold text-center w-100 password-clr p-1">
          Add Adminstrators
        </div>
        <div className="p-1 ">
          <div className="d-flex flex-column my-2">
            <div className="password-clr fw-600 font-16 my-2">
              Role <span className="clr-red">*</span>
            </div>

            <select
              className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
              name="role"
              value={userCreationdata.role}
              onChange={handledata}
            >
              <option value="">Select</option>
              {renderOptions()}
            </select>
          </div>
          <div className="d-flex flex-column my-2">
            <div className="password-clr fw-600 font-16 my-2">
              Name <span className="clr-red">*</span>
            </div>

            <input
              type="text"
              placeholder="Enter Name"
              className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
              name="user_name"
              value={userCreationdata.user_name}
              onChange={handledata}
            />
          </div>
          <div className="d-flex flex-column my-2">
            <div className="password-clr fw-600 font-16 my-2">
              Password <span className="clr-red">*</span>
            </div>
            <input
              type="password"
              placeholder="Enter Password"
              className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
              name="password"
              value={userCreationdata.password}
              onChange={handledata}
            />
          </div>
          <div className="d-flex flex-column my-2">
            <div className="password-clr fw-600 font-16 my-2">
              Location <span className="clr-red">*</span>
            </div>
            <input
              type="text"
              placeholder="Enter Location"
              className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
              name="location"
              value={userCreationdata.location}
              onChange={handledata}
            />
          </div>
          <div className="d-flex flex-column my-2">
            <div className="password-clr fw-600 font-16 my-2">
              City <span className="clr-red">*</span>
            </div>

            <select
              className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
              name="city"
              value={userCreationdata.city}
              onChange={handledata}
            >
              <option value="">Enter City</option>
              <option value="Hyderabad">Hyderabad</option>
              <option value="Dubai">Dubai</option>
            </select>
          </div>
          <div className="d-flex flex-column my-2">
            <div className="password-clr fw-600 font-16 my-2">
              State <span className="clr-red">*</span>
            </div>
            <select
              className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
              name="state"
              value={userCreationdata.state}
              onChange={handledata}
            >
              <option value="">Enter State</option>
              <option value="AP">AP</option>
              <option value="Telangana">Telangana</option>
            </select>
          </div>
          <div className="d-flex flex-column my-2">
            <div className="password-clr fw-600 font-16 my-2">
              Country <span className="clr-red">*</span>
            </div>
            <select
              className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
              name="country_code"
              value={userCreationdata.country_code}
              onChange={handledata}
            >
              <option value="">Enter Country</option>
              <option value="91">India</option>
              <option value="94">Sri Lanka</option>
            </select>
          </div>
          <div className="d-flex flex-column my-2">
            <div className="password-clr fw-600 font-16 my-2">
              Phone Number <span className="clr-red">*</span>
            </div>
            <input
              type="number"
              placeholder="Enter Phone Number"
              className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
              name="phone"
              value={userCreationdata.phone}
              onChange={handledata}
            />
          </div>
          {role == "agent" || userCreationdata.role == "user" ? (
            ""
          ) : (
            <div className="d-flex flex-column my-2">
              <div className="password-clr fw-600 d-flex flex-row justify-content-between w-100 font-16 my-2">
                <div>
                  Casino Comm <span className="clr-red">*</span>
                </div>
              </div>

              <input
                type="number"
                placeholder="Enter Comm"
                className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
                name="casino_comm"
                value={userCreationdata.casino_comm}
                onChange={handledata}
              />
            </div>
          )}
          {role == "agent" || userCreationdata.role == "user" ? (
            ""
          ) : (
            <div className="d-flex flex-column my-2">
              <div className="password-clr fw-600 d-flex flex-row justify-content-between w-100 font-16 my-2">
                <div>
                  Other Tours Comm <span className="clr-red">*</span>
                </div>
              </div>
              <input
                type="number"
                placeholder="Enter Comm"
                className="input-grey-bg input-none br-10 p-2 w-fill fw-500"
                name="other_tours_comm"
                value={userCreationdata.other_tours_comm}
                onChange={handledata}
              />
            </div>
          )}

          <div className="mt-3">
            <div
              className="red-bg br-20 clr-white fw-bold py-2 w-100 text-center"
              onClick={handleRoles}
            >
              Add
            </div>
          </div>
        </div>
      </div>
    </IonModal>
  );
}

export default AddAdminPopup;
