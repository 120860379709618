import React, { useRef, useState } from "react";
import { RiArrowLeftLine } from "react-icons/ri";
import { Images } from "./../../images/index";
import { IoAddCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { uploadImages, userEditProfile } from "../../api-folder/apiMethods";
import SuccessPopup from "../popup/SuccessPopup";
import { IoMdAdd } from "react-icons/io";

const EditProfile = () => {
  const navigate = useNavigate();
  const countryCodes = [
    { code: "+91", name: "India" },
    { code: "+971", name: "Dubai" },
    { code: "+1", name: "USA" },
    { code: "+44", name: "UK" },
    { code: "+61", name: "Australia" },
  ];

  const userDetailsData = useSelector((state) => state.userDetailsData);
  console.log(userDetailsData, "user dtaa")
  const fileInputRef = useRef();

  const [editProfileData, setEditProfileData] = useState({});
  console.log(editProfileData, "editProfileData");
  const [error, setError] = useState();
  const [message, setMessage] = useState();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setEditProfileData((prev) => ({
      ...prev,
      [name]:
        name === "phone" ? value.replace(/\D/g,"").slice(0, 10) : files ? files[0].name : value,
    }));
  };

  const [uploadProfileImg, setUploadProfileImg] = useState();
  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      setUploadProfileImg(event.target.files[0]);
      setEditProfileData((prev) => ({
        ...prev,
        profileImage: event.target.files[0].name,
      }));
    }
  };
  console.log(editProfileData, "editProfileData");
  const handleIconClick = () => {
    fileInputRef.current.click();
  };


  const handleCountryCodeChange = (e) => {
    setEditProfileData((prev) => ({
      ...prev,
      country_code: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    try {
      let uploadProfileId = userDetailsData?.upload_id;

      if (uploadProfileImg) {
        const imgData = {
          path: [uploadProfileImg?.name],
          type: "image",
        };

        const responseProfile = await uploadImages(imgData);
        console.log(responseProfile, "responseProfile");

        uploadProfileId = responseProfile?.data[0]?.id;
      }

      const response = await userEditProfile({
        name: editProfileData?.name || userDetailsData?.name,
        country_code:
          editProfileData?.country_code || userDetailsData?.country_code,
        phone: editProfileData?.phone || userDetailsData?.phone,
        upload_id: uploadProfileId,
      });

      if (response?.status === true) {
        console.log("Profile updated successfully", response);
        setMessage({text:"Your Profile Update Successfully",type:"success"});
        setEditProfileData({});
        setTimeout(() => {
          setMessage("");
        }, 500);
        navigate("/home");
      } else {
        setError("Something went wrong while updating the profile");
      }
    } catch (error) {
      setError(error?.message || "An error occurred");
      console.error("Error during profile update:", error);
      setMessage({text:"Your Profile is not Updated", type:"error"});
    }
  };
  return (
    <>
      <div>
        <div className="pink-bg h-6vh clr-white d-flex flex-between align-items-center">
          <div>
            <span onClick={() => navigate("/home")}>
              <RiArrowLeftLine className="font-30 mx-2" />
            </span>
          </div>
          <div>
            <h5 className="mr-4">Edit Profile</h5>
          </div>
          <div></div>
        </div>
        <div className="d-flex flex-column">
          <div className="flex-center mt-2 postion-relative">
            <img
              src={Images.ProfileImage || userDetailsData?.profile_image }
              alt="profile-image"
              className="img-height"
            />

            <div className="clr-lightpink pink-bg br-50 edit-icon ">
              <IoMdAdd
                className="font-30 flex-center"
                onClick={handleIconClick}
              />
            </div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
          <div className="d-flex flex-column">
            <div className="password-clr fw-600 font-16 mx-2 ">Name</div>
            <input
              type="text"
              placeholder="Enter Name"
              className="input-box mx-2 br-5  py-custom w-fill fw-500 mt-1 px-2"
              name="name"
              value={
                editProfileData?.name !== undefined
                  ? editProfileData?.name
                  : userDetailsData?.user_name || ""
              }
              onChange={handleChange}
            />
          </div>
          <div className="d-flex flex-column mt-2">
            <div className="password-clr fw-600 font-16 mx-2 mb-2">
              Phone number
            </div>
            <div className="d-flex">
              <select
                className="input-box br-5  py-custom mx-2 px-2 w-34 fw-500 mt-1 "
                value={
                  editProfileData?.country_code ||
                  userDetailsData?.country_code ||
                  ""
                }
                onChange={handleCountryCodeChange}
              >
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name} ({country.code})
                  </option>
                ))}
              </select>

              <input
                type="tel"
                placeholder="Enter Phone Number"
                className="input-box br-5 py-custom mx-2 w-fill fw-500 mx-2 px-2 mt-1 w-57"
                name="phone"
                value={
                  editProfileData?.phone !== undefined
                    ? editProfileData?.phone
                    : userDetailsData?.phone || ""
                }
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="footer d-flex flex-column flex-center">
          <div
            className="pink-bg border w-90 text-center clr-white py-2 br-20 font-20"
            onClick={handleSubmit}
          >
            Save
          </div>
          {message && (
          <span className={` mt-1 ${message?.type==="success"?"clr-green" : "clr-red"}`}>
          {message.text}
          </span>
          )}
        </div>
      </div>
    </>
  );
};

export default EditProfile;
