import axios from "axios"
import environment from "../environments/environment.ts"

export default{
    forgotPassword(data){
        return new Promise((res,rej)=>{
            axios.post(`${environment.baseUrl}/forgot-password`, data)
            .then((response)=>{
                
                res(response.data);
            })
            .catch((rej)=>{
                res(rej.response?.data)
            })
        },false)
    },

    updatePassword(data){
        return new Promise((res,rej)=>{
            axios.put(`${environment.baseUrl}/update-password`, data)
            .then((response)=>{
                res(response.data)
            })
            .catch((rej)=>{
                res(rej.response?.data)
            })
        },false)
    },

    verifyotp(data){
        return new Promise((res,rej)=>{
            axios.post(`${environment.baseUrl}/verify-otp`, data)
            .then((response)=>{
                res(response.data)
            })
            .catch((rej)=>{
                res(rej.response.data)
            })
        },false)
    },

    resendOtp(data){
        return new Promise((res, rej)=>{
            axios.post(`${environment.baseUrl}/resend-otp`,data)
            .then((response)=>{
                res(response.data)
            })
            .catch((rej)=>{
                res(rej.response?.data)
            })
        },false)
    }
}