import { IonCol, IonGrid, IonImg, IonModal, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Images } from "./../images/index";
import { useNavigate } from "react-router";
import { BiSearchAlt } from "react-icons/bi";
import { FaRegBell } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { setUserDetailsData } from "../redux/action";
import { useDispatch } from "react-redux";
import { api } from "../api/index";

function Header() {
  const navigate = useNavigate();
  const [update, Setupdate] = useState(false);
  const [showProfileDropDown, setShowProfileDropDown] = useState(false);
  const handleShowProfileDropDown = () => {
    setShowProfileDropDown(true);
  };
  const [showSearchbox, setShowSearchBox] = useState(false);
  const handleSearchBox = () => {
    setShowSearchBox(!showSearchbox);
  };

  const [userDetails, setUserDetails] = useState([]);
  console.log(userDetails, "userDetails");
  const dispatch = useDispatch();
  const handleEditUser = () => {
    api
      .getEdituserDetails()
      .then((response) => {
        
        setUserDetails([response?.data]);
        dispatch(setUserDetailsData(response?.data));
      })
      .catch((error) => {
        console.error("Error", error);
      });
  };
  useEffect(() => {
    handleEditUser();
  }, []);

  const handleUpdate = () => {
    Setupdate(true);
    setShowProfileDropDown(false);
  };
  return (
    <IonGrid className="header d-flex align-items-center justify-content-between p-1 pos-relative">
      <IonRow className="d-flex align-items-center w-100">
        <IonCol size="6">
          <IonImg
            src={Images.HeaderLogo}
            className="w-90"
            alt=""
            onClick={() => navigate("/home")}
          />
        </IonCol>
        <IonCol size="1"></IonCol>
        <IonCol size="5" className="flex-center">
          <div className="w-100 d-flex justify-content-between align-items-center">
            <BiSearchAlt
              className="font-35 grey-text"
              onClick={() => handleSearchBox()}
            />
            <FaRegBell
              className="font-30 grey-text"
              onClick={() => navigate("/message")}
            />
            <IonImg
              src={userDetails?.profile_image || Images.ProfileImage}
              className="images-logo position-relative"
              alt=""
              onClick={() => navigate("/edit-profile")}
            />
          </div>
        </IonCol>
      </IonRow>
      {showSearchbox && (
        <div className="search-section border-pink white-bg w-50 d-flex flex-row align-items-center justify-content-between border-grey-not p-2 br-10">
          <input
            type="search"
            placeholder="Search Here"
            className="border-none cross-bg py-2 clr-black w-90 br-20 px-1 w-40vw"
          />
          <RxCross2
            className="font-24"
            onClick={() => {
              setShowSearchBox(false);
            }}
          />
        </div>
      )}

      {/* <UpdateProfile
        handleUpdate={handleUpdate}
        update={update}
        Setupdate={Setupdate}
      /> */}
    </IonGrid>
  );
}

export default Header;
