import axios from "axios";
import environment from "../environments/environments.ts";


const jwt_token = localStorage.getItem("jwt_token");

const chatting = {
  fetchYourCreations: async (myId) => {
    try {
      const response = await axios.get(
        `${environment.baseChattingUrl}/api/creater/users-by-creator/${myId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      //  console.log(response,"response bro ==>");

      return response.data;
    } catch (error) {
      console.error(
        "Error fetching category:",
        error.response?.data || error.message
      );
      throw error;
    }
  },

  getChatMessages: async (id) => {
    console.log(id,"3456");
    
    try {
      const response = await axios.get(
        `${environment.baseChattingUrl}/api/chats/messages/${id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );

      console.log(response.data.data, "creator messages");
      return response.data.data;
    } catch (error) {
      console.error("Error fetching creator details:", error);
    }
  },

  sendMessage: async (id, data) => {
    console.log(id, data, "message sender id ");

    try {
      const response = await axios.post(
        `${environment.baseChattingUrl}/api/chats/messages/${id}  `,
        data,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );
      console.log(response.data, "send message");
      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },

  editMessage: async (id, msgId, data) => {
    console.log(id,msgId,data,"===>edit message in api call ");
    
    try {
      const response = await axios.put(
        `${environment.baseChattingUrl}/api/chats/messages/${id}/${msgId}`,
        {
          content: data, // Send the new message content in the request body
        },
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );
      if (response.status === 200) {
        console.log("Message edited successfully");
      }
    } catch (err) {
      console.error("Error editing message:", err);
    }
  },

  deleteMessageForMe: async (id) => {
    console.log(id,"==>My message Deleted id==> ");
    
    try {
      const response = await axios.delete(
        `${environment.baseChattingUrl}/api/chats/messages/me/${id}`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Message deleted successfully");
      }
    } catch (err) {
      console.error("Error deleting message:", err);
    }
  },

  deleteMessageForAll: async (id, msgId) => {
    try {
      const response = await axios.delete(
        `${environment.baseChattingUrl}/api/chats/messages/${id}/${msgId}`,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Message deleted successfully");
      }
    } catch (err) {
      console.error("Error deleting message:", err);
    }
  },
  blockTheUser: async (id) => {
    try {
      const response = await axios.post(
        `${environment.baseChattingUrl}/api/users/block/${id}  `,
        {},

        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },
  forwardMessages: async (messageId, recipientIds, data) => {
    console.log(messageId, recipientIds, data, "==>in api method");

    try {
      const response = await axios.post(
        `${environment.baseChattingUrl}/api/chats/messages/forward/${messageId}/${recipientIds}  `,
        data,
        {
          headers: {
            Authorization: `Bearer ${jwt_token}`, // Include the token in the Authorization header
          },
        }
      );
      console.log(response.data, "send message");
      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
    }
  },
};

export default chatting;
