import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { IoArrowBack, IoClose } from "react-icons/io5";
import { FaHeart } from "react-icons/fa6";
import { FaCommentDots } from "react-icons/fa6";
import { Images } from "../../images";
import { HiDotsVertical, HiReply } from "react-icons/hi";
import { useNavigate, useParams } from "react-router";
import { FaEdit, FaFlag, FaTrashAlt } from "react-icons/fa";
import {
  EditCommentsById,
  getMemoryDetailsById,
  AddCommentsById,
  DeletecommentById,
  replyComment,
} from "../../api-folder/apiMethods";
import { IoIosArrowDown, IoIosArrowUp, IoMdSend } from "react-icons/io";
import DeletePopup from "../popup/DeletePopup";
import { LuReply } from "react-icons/lu";
import moment from "moment";

function LikesPage() {
  const { id } = useParams(); // memory_id
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("like");
  const [openedCommentId, setOpenedCommentId] = useState();
  const [comment, setComment] = useState("");
  const [editingCommentId, setEditingCommentId] = useState();
  const [memoryData, setMemoryData] = useState([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [commentid, setCommentid] = useState();

  const login_userid = localStorage.getItem("user_id");
  const loginUserId = Number(login_userid);
  console.log(loginUserId, "login user id");
  const handleDeletePopup = (delcommentId) => {
    setOpenedCommentId(null);
    setShowDeletePopup(true);
    setCommentid(delcommentId);
    console.log("delet opo");
  };

  const fetchMemorydetails = () => {
    getMemoryDetailsById(id)
      .then((response) => {
        if (response.status === true) {
          setMemoryData(response?.data);
          console.log(response.data, "memory details by id");
        }
      })
      .catch((error) => console.log(error?.message));
  };
  useEffect(() => {
    fetchMemorydetails();
  }, [id]);

  const handleOptions = (commentId) => {
    setOpenedCommentId(openedCommentId === commentId ? null : commentId);
    
    
  };

  const [replyingCommentId, setReplyingCommentId] = useState();
  const [replyText, setReplyText] = useState("");
  const handleReplyClick = (commentId) => {
    setReplyingCommentId(replyingCommentId === commentId ? null : commentId);
    console.log(replyingCommentId,"commentId")
  };

  const handleEdit = (commentId, commentText) => {
    setOpenedCommentId(null);
    setEditingCommentId(commentId);
    setComment(commentText);
    console.log("edit");
  };

  const postComment = () => {
    if (editingCommentId) {
      EditCommentsById(editingCommentId, { comment })
        .then((response) => {
          if (response.status === true) {
            console.log(response, "Edited response");
            setEditingCommentId(null);
            setComment("");
            fetchMemorydetails();
          }
        })
        .catch((error) => console.log(error?.message));
    } else {
      AddCommentsById(id, { comment })
        .then((response) => {
          if (response.status === true) {
            console.log(response, "responseee");
            setComment("");
            fetchMemorydetails();
          }
        })
        .catch((error) => console.log(error?.message));
    }
  };

  // delete comment
  const handleDeleteComment = () => {
    console.log(commentid, "DELETE API");
    if (commentid) {
      DeletecommentById(commentid)
        .then((response) => {
          if (response.status === true) {
            fetchMemorydetails();
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error?.message));
    }
    setShowDeletePopup(false);
  };

  const handleSubmitReply = (parentcommentId) => {
    const replyData = {
      parent_comment_id: parentcommentId,
      comment: replyText,
    };
    console.log(replyData, "replyData");
    replyComment(id, replyData)
      .then((response) => {
        if (response.status === true) {
          console.log(response, "relpy to comments");
          setReplyText("");
          setReplyingCommentId(null);
          fetchMemorydetails();
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };

  const handleClosereplySection = () => {
    setReplyingCommentId(null);
    setReplyText("");
  };

  const [showReplies, setShowReplies] = useState();
  const openReplies = (commentId) => {
    setShowReplies(showReplies === commentId ? null : commentId);

    console.log("show replies");
  };

  const formatDateTime = (time) => {
    const dataObj = new Date(time);
    const formatDate = dataObj.toLocaleDateString("en-GB");
    const formatTime = dataObj.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formatDate}, ${formatTime}`;
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !event.target.closest('.pos-delete-option')) {
        setOpenedCommentId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      <div>
        <div className="h-6vh likes-header-bg fw-bold font-20 clr-white flex-start-center ">
          <IoArrowBack
            className="font-30 mx-2"
            onClick={() => navigate("/memories")}
          />
          People who likes and comments
        </div>
        <div className="p-2 mt-2 d-flex flex-center">
          <img
            src={Images?.TourVideo2 || memoryData?.memory_image}
            alt="big image"
          />
        </div>
        <div className="w-100 d-flex flex-row align-items-center border-bottom-like">
          <div
            className={`flex-row-jc-c w-50 font-20 fw-bold py-2 ${
              activeButton === "like" ? "border-bottom-pink" : ""
            }`}
            onClick={() => setActiveButton("like")}
          >
            <FaHeart className="font-24 mr-1 pink-clr" />
            Likes({memoryData?.likes_count})
          </div>
          <div
            className={`flex-row-jc-c w-50 font-20 fw-bold py-2 ${
              activeButton === "comments" ? "border-bottom-pink" : ""
            }`}
            onClick={() => setActiveButton("comments")}
          >
            <FaCommentDots className="font-24 mr-1 comment-clr" />
            Comments ({memoryData?.comments_count})
          </div>
        </div>

        {activeButton === "like" && (
          <div className="white-bg message-sec">
            {memoryData?.likess?.map((data, index) => (
              <div
                key={index}
                className="d-flex flex-row border-bottom-grey p-2"
              >
                <div className="w-20 d-flex justify-content-center pos-relative">
                  <img
                    src={Images.Boy || data?.like_user_profile_image}
                    alt={`Avatar`}
                  />
                  <div className="like-pink-bg br-50 like-highlight pos-like">
                    <FaHeart
                      className="clr-white"
                      style={{ fontSize: "8px" }}
                    />
                  </div>
                </div>
                <div className="w-80 d-flex flex-column">
                  <div className="font-14 fw-bold">{data?.like_user_name}</div>

                  <div>{formatDateTime(data?.like_createdAt)}</div>
                </div>
              </div>
            ))}
          </div>
        )}

        {activeButton === "comments" && (
          <>
            <div className="scrollable-comments">
              {memoryData?.comments?.map((msg, index) => (
                <div className="d-flex flex-column px-2">
                  <div className="d-flex flex-row  align-items-start my-2 w-100">
                    <div className="w-20 d-flex justify-content-center">
                      <img
                        src={Images.Boy || msg?.comment_user_profile_image}
                        alt="avatar"
                        className="h-5vh"
                      />
                    </div>
                    <div className="d-flex flex-column w-80"  ref={dropdownRef}>
                      <div className="d-flex flex-column font-14 input-bg br-10 p-2 position-relative" >
                        <div className="d-flex flex-between">
                          <div>{msg?.comment_user_name}</div>
                          <div>
                            {msg?.comment_user_id === loginUserId && (
                              <span className="">
                                <HiDotsVertical
                                  className="cursor"
                                  onClick={() => handleOptions(msg?.comment_id)}
                                />
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="d-flex flex-end">
                          {openedCommentId === msg?.comment_id && (
                            <div className="d-flex flex-column pos-delete-option p-2 br-10">
                              <div className="clr-black py-1 border-bottom-grey small-font d-flex align-items-center">
                                <FaTrashAlt className="mr-1" />

                                <span
                                  onClick={() =>
                                    handleDeletePopup(msg?.comment_id)
                                  }
                                >
                                  Delete
                                </span>
                              </div>
                              <div
                                className="clr-black py-1 small-font"
                                onClick={() =>
                                  handleEdit(msg?.comment_id, msg?.comment)
                                }
                              >
                                <FaEdit className="mr-1" />
                                Edit
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="grey-text mt-1">{msg?.comment}</div>
                      </div>
                      <div className="d-flex flex-column">
                        <div className="flex-row font-14 px-2 flex-between grey-text mt-1">
                          <div className="">
                            {formatDateTime(msg?.created_at)}
                          </div>

                          {msg?.comment_user_id !== loginUserId && (
                            <span
                              className=""
                              onClick={() => handleReplyClick(msg?.comment_id)}
                            >
                              <LuReply className="clr-blue" />
                              Reply
                            </span>
                          )}
                        </div>

                        <div>
                          {replyingCommentId === msg?.comment_id && (
                            <div className="d-flex flex-column mt-2 w-100 px-3 py-3">
                              <input
                                type="text"
                                className="input-none border-bottom-pink"
                                placeholder="Add a reply..."
                                value={replyText}
                                onChange={(e) => setReplyText(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleSubmitReply(msg?.comment_id);
                                  }
                                }}
                              />
                              <div className="d-flex flex-end mt-2">
                                <div
                                  className="input-bg px-2 mx-1 py-1 br-20"
                                  onClick={() =>
                                    handleSubmitReply(msg?.comment_id)
                                  }
                                >
                                  Reply
                                </div>
                                <div
                                  className=" px-1 py-1 ms-1 "
                                  onClick={handleClosereplySection}
                                >
                                  cancel
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="mt-2 mb-2 d-flex flex-start flex-column">
                        <span
                          className={`clr-blue cursor-pointer ${
                            msg?.replies?.length === 0 ? "" : "input-reply-bg"
                          }  px-1 py-1 br-10 w-2`}
                          onClick={() => openReplies(msg?.comment_id)}
                        >
                          {msg?.replies?.length > 0 && (
                            <span>
                              {showReplies ? (
                                <span className="d-flex">
                                  <IoIosArrowUp className="font-medium" />
                                  {msg?.replies?.length === 1
                                    ? `${msg?.replies?.length} reply`
                                    : `${msg?.replies?.length} replies`}
                                </span>
                              ) : (
                                <span className="d-flex">
                                  <IoIosArrowDown className="font-medium" />
                                  {msg?.replies?.length === 1
                                    ? `${msg?.replies?.length} reply`
                                    : `${msg?.replies?.length} replies`}
                                </span>
                              )}
                            </span>
                          )}
                        </span>

                        {showReplies === msg?.comment_id &&
                          msg?.replies?.map((reply, index) => (
                            <div className="d-flex input-bg p-1 mt-2 br-5">
                              <img
                                className="h-5h"
                                src={
                                  Images.ProfileImage ||
                                  reply?.comment_user_profile_image
                                }
                                alt="profile"
                              />
                              <div className="d-flex flex-column">
                                <div className="d-flex flex-row align-items-end">
                                  <div className="mx-1 font-12">
                                    {reply?.comment_user_name}
                                  </div>
                                  <div className="mx-1 font-10 mt-1">
                                    {moment(reply?.created_at).fromNow()}
                                  </div>
                                </div>
                                <div>
                                  <div className="ms-2 font-12">
                                    {reply?.comment}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                // </div>
                // </div>
                // </div>
              ))}
            </div>

            <div className="input-bg footer">
              <div className="w-95 cross-bg d-flex flex-center align-items-center py-2 mx-2 border-grey br-10">
                <input
                  type="text"
                  className="py-2 cross-bg border-none px-2 br-10 w-90"
                  placeholder="Add a Comment........."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      postComment();
                    }
                  }}
                ></input>
                <div>
                  <IoClose
                    className="font-35 p-1 white-bg br-50 boxShadow mx-2 pink-clr"
                    onClick={() => {
                      setComment("");
                      setReplyText("");
                    }}
                  />
                </div>
                <div className="d-flex text-center w-10 mx-2">
                  <IoMdSend
                    className="font-35 p-1 white-bg br-50 boxShadow pink-clr"
                    onClick={postComment}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <DeletePopup
        showConfirmationPopup={showDeletePopup}
        setShowConfirmationPopup={setShowDeletePopup}
        handleConfirm={handleDeleteComment}
        description="Are you sure to delete this comment?"
      />
    </>
  );
}

export default LikesPage;
