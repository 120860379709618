import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { Images } from "../images";
import Notification from "./Notification";

function Message() {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleActivebutton = (index) => {
    setActiveIndex(index);
  };
  const buttons = ["Message", "Notification"];
  return (
    <div className="homecontent-sec grey-bg1 w-100 border-grey-not p-1 h-90vh">
      <div className="d-flex flex-row justify-content-between align-items-center w-100 p-2">
        <div className="w-75 d-flex flex-row align-items-center justify-content-around">
          {buttons.map((btn, index) => (
            <div
              key={index}
              className={
                activeIndex === index
                  ? "pink-bg clr-white font-16 fw-600 px-2 py-2 br-5"
                  : "input-bg clr-black font-16 fw-600 px-2 py-2 br-5"
              }
              onClick={() => handleActivebutton(index)}
            >
              {btn}
            </div>
          ))}
        </div>
      </div>
      {activeIndex === 0 && (
        <div className="white-bg message-sec">
          {Array.from({ length: 5 }, (_, index) => (
            <div key={index} className="d-flex flex-row border-bottom-grey p-2">
              <div className="w-20 d-flex justify-content-center">
                <img src={Images.ProfileImage} alt={`Avatar ${index + 1}`} />
              </div>
              <div className="w-80 d-flex flex-column">
                <div className="d-flex flex-row w-100 justify-content-between align-items-center font-14">
                  <div>Sangram Keshari Rath</div>
                  <div>Feb 14 2023</div>
                </div>
                <div className="grey-text  font-14 mt-1">
                  Hello Jayanta! Saw your design work..
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {activeIndex === 1 && <Notification />}
    </div>
  );
}

export default Message;
