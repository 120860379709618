import React, { useEffect, useState, useRef } from "react";
import ChatFooter from "./ChatFooter";
import { useParams } from "react-router";
import chatting from "../../api/chatting";
import { FaCheck, FaCheckDouble } from "react-icons/fa";
import {
  setChangeHeader,
  setMessageContent,
  setSelectedUser,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import {
  connectSocket,
  disconnectSocket,
  onEvent,
  emitEvent,
  offEvent,
} from "./../../utils/socketio";

function ChatScreen() {
  const { id } = useParams();
  const [message, setMessage] = useState([]);
  const [longPressTimeout, setLongPressTimeout] = useState(null);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  const [showOptions, setShowOptions] = useState(null); // Track the ID of the message with options shown
  const [socketConnected, setSocketConnected] = useState(false);
  const dispatch = useDispatch();
  const myId = localStorage.getItem("myid");
  const sendMessageText = useSelector((state) => state.sendMessageText);
  const selectedUser = useSelector((state) => state?.selectedUser);

  const handleGetMessages = async () => {
    try {
      const response = await chatting.getChatMessages(id);

      const filteredMessages = response.filter((message) => !message.deletedBy);

      setMessage(filteredMessages);
    } catch (error) {
      console.error("Error getting messages:", error);
    }
  };
  console.log(sendMessageText, "==>sendMessageText from redux");

  useEffect(() => {
    handleGetMessages();
  }, [id]);

  useEffect(() => {
    if (sendMessageText) {
      const messageWithTimestamp = {
        ...sendMessageText,
        timestamp: sendMessageText.timestamp || new Date().toISOString(), // Add current timestamp if missing
      };

      setMessage((prevMessages) => [...prevMessages, messageWithTimestamp]);
    }
  }, [sendMessageText]);

  useEffect(() => {
    let registeredHandler;
    let messageHandler;

    if (selectedUser.userId) {
      if (!socketConnected) {
        connectSocket();
        emitEvent("register", selectedUser.userId);

        // Define the handlers
        registeredHandler = (data) => {
          console.log("User registered 123:", data);
        };

        messageHandler = (message) => {
          console.log("New message received:", message);
          setMessage((prevMessages) => [...prevMessages, message]);
        };

        // Clean up previous listeners and add new ones
        offEvent("registered", registeredHandler);
        onEvent("registered", registeredHandler);

        offEvent("chat:receiveMessage", messageHandler);
        onEvent("chat:receiveMessage", messageHandler);

        console.log(`Connected with user: ${selectedUser.userId}`);
        setSocketConnected(true);
      }
    } else if (socketConnected) {
      disconnectSocket();
      setSocketConnected(false);
      console.log("Disconnected due to no chatting user");
    }

    return () => {
      if (socketConnected) {
        // Cleanup socket connection and listeners
        offEvent("registered", registeredHandler);
        offEvent("chat:receiveMessage", messageHandler);
        disconnectSocket();
        setSocketConnected(false);
        console.log(`Disconnected from user: ${selectedUser.userId}`);
      }
    };
  }, [selectedUser, socketConnected]);

  // Function to handle the display of options box
  const handleOptionsClick = (msgId) => {
    if (showOptions === msgId) {
      setShowOptions(null); // Hide if already shown
    } else {
      setShowOptions(msgId); // Show options for the clicked message
    }
  };

  // Function to handle any interaction on the screen to hide the options box
  const handleAnyInteraction = () => {
    setShowOptions(null); // Hide the options box on any interaction
  };

  // Click to deselect
  const handleMessageClick = (index) => {
    if (selectedMessageIndex === index) {
      setSelectedMessageIndex(null); // Deselect the message if it's already selected
      dispatch(setChangeHeader(false)); // Reset header change
    }
  };

  // Edit message function
  const handleEditMessage = async (msgId, content) => {
    console.log("edit message block ", msgId);
    const response = await chatting.editMessage(msgId, content);
    console.log(response);

    // console.log("Editing message with ID:", msgId);
    // Your edit logic here
  };

  // Delete message function (for self)
  const handleDeleteMessage = async (msgId) => {
    const response = await chatting.deleteMessageForMe(msgId);
    console.log(response);

    // Your delete logic here
  };

  // Delete message function (for all)
  const handleDeleteForAll = async (id, msgId) => {
    console.log(id, msgId, "===> for all");

    const response = await chatting.deleteMessageForAll(id, msgId);

    console.log(response);

    // Your delete for all logic here
  };

  const handleLongPressStart = (index, content, id) => {
    console.log(index, content, id, "===>message details in chat screen ");

    if (selectedMessageIndex === index) {
      dispatch(setChangeHeader(false));
      setSelectedMessageIndex(null);
      dispatch(setMessageContent({ id: id, content: content }));
      // dispatch(setSelectedMessageId(id))
    } else {
      const timeout = setTimeout(() => {
        setSelectedMessageIndex(index);
        dispatch(setChangeHeader(true));
      }, 500);
      setLongPressTimeout(timeout);
    }
  };
  const handleLongPressEnd = () => {
    clearTimeout(longPressTimeout); // Clear timeout if press is released early
  };

  return (
    <div>
      <div className="d-flex flex-column chat-scroll px-4">
        {message.length > 0 &&
          message.map((msg, index) => (
            <div
              key={index}
              className={`d-flex flex-column w-100 position-relative mt-3 ${
                selectedMessageIndex === index ? "highlighted-message" : ""
              }`}
              onClick={() => handleMessageClick(index)} // Click handler to deselect
            >
              <div
                className={
                  Number(msg?.senderId) === Number(myId)
                    ? "w-100 d-flex justify-content-start"
                    : "w-100 d-flex justify-content-end"
                }
                onTouchStart={() =>
                  handleLongPressStart(index, msg.content, msg.id)
                } // Mobile: Detect long press start
                onTouchEnd={handleLongPressEnd} // Mobile: Detect press release
              >
                <div className="d-flex flex-column">
                  <div
                    className={`${
                      selectedMessageIndex === index
                        ? "highlighted-message"
                        : ""
                    } ${
                      Number(msg?.senderId) === Number(myId)
                        ? "grey-text grey-bg2 font-14 p-2 br-10 d-flex flex-column"
                        : "outgoing-chat-clr lightgreen-bg font-14 p-2 br-10"
                    }`}
                  >
                    {msg?.messageType === "image" ? (
                      <img
                        src={msg?.mediaUrl || msg?.content}
                        alt="Message Image"
                        className="chatImagesize"
                      />
                    ) : typeof msg?.content === "string" &&
                      msg?.content.includes("http") ? (
                      <img
                        src={msg?.content}
                        alt="Chat Image"
                        className="chatImagesize"
                      />
                    ) : (
                      <div className="font-bold">{msg?.content}</div>
                    )}
                  </div>
                  <div>
                    <div className="grey-text font-12  d-flex flex-between mx-2 ">
                      <span className="timestamp small-font">
                        {new Date(msg.timestamp).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </span>

                      {Number(msg?.senderId) !== Number(myId) && (
                        <FaCheckDouble className="font-10 mx-1" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ChatScreen;
