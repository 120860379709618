import React, { useState } from "react";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { IoBriefcaseOutline } from "react-icons/io5";
import { IoImageOutline } from "react-icons/io5";
import { RiMenuFill } from "react-icons/ri";
import { RiHome6Fill } from "react-icons/ri";
import "./common.css";
import "./styles.css";
import { RxCross2 } from "react-icons/rx";
import { IonCol, IonRow } from "@ionic/react";
import { useNavigate } from "react-router";
import SideBar from "./SideBar";

function Footer() {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("home");

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const [showTours, setShowTours] = useState(false);
  const handleTours = () => {
    navigate("/tours");
    handleButtonClick("tours");
    setShowTours(!showTours);
  };
  const [showAdmins, setShowAdmins] = useState(false);
  const handleAdmins = () => {
    navigate("/admins");
    handleButtonClick("admins");
    setShowAdmins(!showAdmins);
  };

  const [showHome, setShowHome] = useState(false);
  const handleHome = () => {
    navigate("/home");
    handleButtonClick("home");
    setShowHome(!showHome);
  };
  const [showMemories, setShowMemories] = useState(false);
  const handleReports = () => {
    navigate("/reports");
    handleButtonClick("memories");
    setShowMemories(!showMemories);
  };
  const [showMore, setShowMore] = useState(false);
  const handleMore = () => {
    handleButtonClick("menu");
    setShowMore(!showMore);
    setShowTours(false);
  };
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const handleResetPassword = () => {
    setOpenResetPassword(true);
    setShowMore(false);
  };
  const handleTickets = () => {
    navigate("/tickets");
    setShowMore(false);
  };
  const handleChat = () => {
    navigate("/chat");
    setShowMore(false);
  };
  return (
    <div className="footer">
      <IonRow>
        <IonCol size="2.4">
          <div
            className={`d-flex flex-column justify-content-center align-items-center ${
              activeButton === "tours"
                ? "red-clr border-top-pink py-1"
                : "py-1 clr-grey"
            }`}
            onClick={() => handleTours()}
          >
            <BiSolidPlaneAlt className="font-30" />
            <div className="font-16">Tours</div>
          </div>
        </IonCol>
        <IonCol size="2.4">
          <div
            className={`d-flex flex-column justify-content-center align-items-center ${
              activeButton === "admins"
                ? "red-clr border-top-pink py-1"
                : "py-1 clr-grey"
            }`}
            onClick={() => {
              handleAdmins();
            }}
          >
            <IoBriefcaseOutline className="font-30" />
            <div className="font-16">Adminis</div>
          </div>
        </IonCol>
        <IonCol size="2.4">
          <div
            className={`home-icon flex-center br-50 p-2 clr-grey ${
              activeButton === "home" ? "red-bg" : "bg-grey"
            }`}
            onClick={() => handleHome()}
          >
            <RiHome6Fill className="font-35 clr-white" />
          </div>
        </IonCol>
        <IonCol size="2.4">
          <div
            className={`d-flex flex-column justify-content-center align-items-center ${
              activeButton === "memories"
                ? "red-clr border-top-pink py-1"
                : "py-1 clr-grey"
            }`}
            onClick={() => handleReports()}
          >
            <IoImageOutline className="font-30" />
            <div className="font-16">Report</div>
          </div>
        </IonCol>
        <IonCol size="2.4">
          <div
            className={`d-flex flex-column justify-content-center align-items-center ${
              activeButton === "menu"
                ? "red-clr border-top-pink py-1"
                : "py-1 clr-grey"
            }`}
            onClick={() => handleMore()}
          >
            <RiMenuFill className="font-30" />
            <div className="font-16">Menu</div>
          </div>
        </IonCol>
        {/* {showTours && (
          <div className="white-bg clr-black tours-popup border-grey-not br-20 w-40 px-2">
            <div className="d-flex justify-content-center align-items-center mtn-10p">
              <div className="grey-bg1 w-20 text-center br-50">
                <RxCross2
                  className="font-20 fw-600 clr-white"
                  onClick={() => setShowTours(false)}
                />
              </div>
            </div>
            <div className="overflow-auto h-17vh">
              <div className="grey-text p-1  border-bottom-grey">
                Adventure Tour
              </div>
              <div className="grey-text p-1  border-bottom-grey">
                Musical Tour
              </div>
              <div
                className="grey-text p-1  border-bottom-grey"
                onClick={() => {
                  navigate("/casino");
                  setShowTours(false);
                }}
              >
                Casino Tour
              </div>
              <div className="grey-text p-1  border-bottom-grey">
                Sports Tour
              </div>
              <div className="grey-text p-1  border-bottom-grey">
                Adventure Tour
              </div>
              <div className="grey-text p-1  border-bottom-grey">
                Musical Tour
              </div>
              <div className="grey-text p-1  border-bottom-grey">
                Casino Tour
              </div>
              <div className="grey-text p-1  border-bottom-grey">
                Sports Tour
              </div>
            </div>
          </div>
        )} */}

        {showMore && <SideBar setShowMore={setShowMore} />}
      </IonRow>
      {/* <ResetPassword
        openResetPassword={openResetPassword}
        setOpenResetPassword={setOpenResetPassword}
      /> */}
    </div>
  );
}

export default Footer;
