
import createRoles from "./createRoles";
import forgotPassword from "./forgotPassword";
import category from "./getCategory";
import getTours from "./getTours";
import loginapi from "./loginapi";



export const api={
    ...loginapi,
    ...category,
    ...getTours,
    ...createRoles,
    ...forgotPassword
    
    
    

    
}