import { io } from "socket.io-client";

const SOCKET_URL = "http://localhost:5001"; 

const socket = io(SOCKET_URL, {
  autoConnect: false, 
});


export const connectSocket = () => {
  if (!socket.connected) {
    socket.connect();
  }
};


export const disconnectSocket = () => {
  if (socket.connected) {
    socket.disconnect();
  }
};


export const emitEvent = (event, data) => {
  socket.emit(event, data);
};


export const onEvent = (event, callback) => {
  socket.on(event, callback);
};


export const offEvent = (event) => {
  socket.off(event);
  
  
};

export default socket;
