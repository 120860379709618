import { Images } from "../../images";
import React, { useEffect, useRef, useState } from "react";
import { MdBlock } from "react-icons/md";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import BlockUser from "../popup/BlockUser";
import { TbCornerUpRightDouble } from "react-icons/tb";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { setChangeHeader, setClickedEdit } from "../../redux/action";
import { IonItem, IonList, IonModal } from "@ionic/react";
import chatting from "../../api/chatting";
import ForwardMessage from "../popup/ForwardMessage";

function ChatHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [blockUser, setBlockUser] = useState(false);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [myAcceptedFriends, setMyAcceptedFriends] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const optionsRef = useRef(null);
  const messageContent = useSelector((state) => state.messageContent);
  const selectedUser = useSelector((state) => state?.selectedUser);
  const changeHeader = useSelector((state) => state?.changeHeader);
  const myId = localStorage.getItem("myid");


  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setIsOptionsVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleOptions = () => {
   
    setIsOptionsVisible(!isOptionsVisible);
   
  };

  

  const handleGetBack=()=>{
    navigate("/chat")
    dispatch(setChangeHeader(false))
    dispatch(setClickedEdit(false))
  }
  const handleEdit = (event) => {
    event.preventDefault();
   
    dispatch(setClickedEdit(true)); 
  
    setIsOptionsVisible(false)
  };


  const handleDeleteMessage = async () => {
    try {
      const response = await chatting.deleteMessageForMe(messageContent?.id);
  
      console.log("Message deleted successfully", response);
      
    } catch (error) {
  
      console.error("Failed to delete message", error);
    }
  };

  const handleDeleteMessageForBothusers = async () => {
    try {
      const response = await chatting.deleteMessageForAll(
        selectedUser.userId,
        messageContent?.id
      );
   
      console.log("Message deleted for both users successfully", response);
     
    } catch (error) {
   
      console.error("Failed to delete message for both users", error);
    }
  };

  const getMyFriendsAll = async () => {
    const response = await chatting.fetchYourCreations(myId);
   
    
    setMyAcceptedFriends(response?.data);
  };

  useEffect(()=>{
    getMyFriendsAll()
  },[])

  return (
    <>
      {changeHeader ? (
        <div className="header">
          <div className="white-bg d-flex flex-row justify-content-between align-items-center w-100 py-1 border-bottom-grey">
            <div className="d-flex flex-row p-2 w-75 align-items-center">
              <FaArrowCircleLeft
                className="font-30 grey-text mr-1"
                onClick={handleGetBack}
              />
              <img
                src={Images?.chatpic2}
                className="online-chat-profile"
                alt="user"
              />
              <div className="d-flex flex-column mx-2">
                <div className="password-clr medium-font fw-600">
                  {selectedUser?.userName}
                </div>
              
              </div>
            </div>
            <div className="d-flex flex-row align-items-center w-25 justify-content-around">
              
              <TbCornerUpRightDouble
                className="font-24 grey-text"
                onClick={() => setIsModalOpen(true)}
              />
              <HiOutlineDotsVertical
                className="font-24 grey-text"
                onClick={toggleOptions}
              />
              <IonModal
                isOpen={isOptionsVisible}
                 cssClass="edit-popup auto-height-modal"
                onDidDismiss={() => setIsOptionsVisible(false)}
              >
               
                  <IonList>
                    <IonItem button onClick={handleEdit}>
                      Edit
                    </IonItem>
                    <IonItem button onClick={handleDeleteMessage}>
                      Delete
                    </IonItem>
                    <IonItem button onClick={handleDeleteMessageForBothusers}>
                      Delete All
                    </IonItem>
                  </IonList>
               
              </IonModal>
            </div>
          </div>
        </div>
      ) : (
        <div className="header">
          <div className="white-bg d-flex flex-row justify-content-between align-items-center w-100 py-1 border-bottom-grey">
            <div className="d-flex flex-row p-2 w-75 align-items-center">
              <FaArrowCircleLeft
                className="font-30 grey-text mr-1"
                onClick={() => navigate("/chat")}
              />
              <img
                src={Images?.chatpic2}
                className="online-chat-profile"
                alt="user"
              />
              <div className="d-flex flex-column mx-2">
                <div className="password-clr medium-font fw-600">
                  {selectedUser?.userName}
                </div>
               
              </div>
            </div>
            <div className="d-flex flex-row align-items-center w-25 justify-content-around">
             
             
              <MdBlock
                className="font-24 grey-text"
                onClick={() => setBlockUser(true)}
              />
            </div>
          </div>
         
          <BlockUser blockUser={blockUser} setBlockUser={setBlockUser} />
          <ForwardMessage
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
           
            myAcceptedFriends={myAcceptedFriends}
          />
        </div>
      )}
    </>
  );
}

export default ChatHeader;
