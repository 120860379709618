const endpoints = {
  loginUser: { method: "post", url: "/login-user" },
  signUpUser: { method: "post", url: "/sign-up-user" },
  tourCategory: { method: "get", url: "/category" },
  resetPassword: { method: "put", url: "/reset-password" },
  userEditProfile: { method: "put", url: "/user-edit-profile" },
  upcomingMyTrips: { method: "get", url: "/upcoming" },
  completedMyTrips: { method: "get", url: "/completed" },
  TripTickets: { method: "get", url: "/user-tickets" },
  getUserDetails: { method: "get", url: "/get-user-details" },
  postForgotPassword: { method: "post", url: "/forgot-password" },
  postVerifyOTP: { method: "post", url: "/verify-otp" },
  updatePassword: { method: "put", url: "/update-password" },
  getPublishedTours: { method: "get", url: "/get-published-tours" },
  getBestDestination: { method: "get", url: "/get-best-destination" },
  getPublishedTourById: {
    method: "get",
    url: (id) => `/get-published-tours/${id}`,
  },
  getBankDetailsByTourId: {
    method: "get",
    url: (tour_id) => `/banking-by-tourId/${tour_id}`,
  },
  getTravelReports: { method: "get", url: "/agent/travelreportagent" },

  getDownlineTravelReportsById: {
    method: "get",
    url: (id) => `/agent/travelreportagent/${id}`,
  },
  getAllMemories: { method: "get", url: "/agent/get-all-memories" },
  AddCommentsById: {
    method: "post",
    url: (id) => `/agent/agent-comment/${id}`,
  },
  getMemoryDetailsById: {
    method: "get",
    url: (id) => `/agent/get-memory-details-by-id/${id}`,
  },
  EditCommentsById: {
    method: "put",
    url: (id) => `/agent/agent-edit-comment/${id}`,
  },
  DeletecommentById: {
    method: "delete",
    url: (id) => `/agent/agent-delete-comment/${id}`,
  },
  PostLike: { method: "post", url: "/agent/like" },
  disLike: { method: "delete", url: (id) => `/agent/dislike/${id}` },
  replyComment: { method: "post", url: (id) => `/agent/agent-comment/${id}` },
  getTravelReportDetailsSummaryById: {
    method: "get",
    url: (id) => `/agent/travelreportagent-summary/${id}`,
  },
  getTravelReportDetailsById: {
    method: "get",
    url: (id) => `/agent/travelreportagent/${id}`,
  },
  uploadImages:{method:"post", url:"/upload"},


getAllTours: {method:"get", url: "/tours"},
getItineraryTours :{method : "get", url: (id) => `/tours?itineary=$(id)`},
getApprovedTours : { method: "get", url: (id) => `/tours?approved=${id}`},
getOngoingTours: { method: "get", url:(id) => `/tours?ongoing=${id}` },
getRejectedTours: { method: "get", url: (id) => `/tours?rejected=${id}`},

  //WalletAgent
  getWalletTable: {method: "get", url:"/agent/tickets-table"},
  getWalletStatus: {method: "get", url: "/agent/tickets-stats"},

  //withdraw
  postWithdraw: {method:"post", url:"/agent/withdraw"},
  createUserDeposit: { method: "post", url: "/agent/deposit" },
  getAllDepositBankings: { method: "get", url: "/get-deposit-bankings" },

  deleteUserDepositDetailsByID: {
    method: "delete",
    url: (id) => `/user/deposit/${id}`,
  },

  deleteUserWithdrawDetailsByID: {
    method: "delete",
    url: (id) => `/agent/withdraw/${id}`,
  },
};

export default endpoints;
