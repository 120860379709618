import React, { useState, useEffect } from "react";
import { FcGallery } from "react-icons/fc";
import { IoSend } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import chatting from "../../api/chatting";
import {
  setClickedEdit,
  setMessages,
  setSendMessageText,
} from "../../redux/action";
import { uploadFilesToS3 } from "../../api-folder/s3services";
import moment from "moment";
import { disconnectSocket, emitEvent, offEvent, onEvent } from "../../utils/socketio";

function ChatFooter() {
  const selectedUser = useSelector((state) => state.selectedUser);
  const clickedEdit = useSelector((state) => state.clickedEdit);
  const messageContent = useSelector((state) => state.messageContent);
  const [isTyping, setIsTyping] = useState(false);
  const [typingUsers, setTypingUsers] = useState([]);
  const [inputMessage, setInputMessage] = useState(""); 
  const [editContent, setEditContent] = useState(""); 
  const [selectedFile, setSelectedFile] = useState();
  const [uploadPosterURL, setUploadPosterURL] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const myId = localStorage.getItem("myid");
  

  useEffect(() => {
    if (clickedEdit && messageContent) {
      setEditContent(messageContent.content || "");
    }
  }, [clickedEdit, messageContent]);

  const handleInputMessage = (e) => {
    setInputMessage(e.target.value);
  };

  const handleInputEdit = (e) => {
    handleTyping()
    setEditContent(e.target.value);
  };

  useEffect(() => {
    onEvent("user:typing", ({ senderId }) => {
      if (!typingUsers.includes(senderId)) {
        setTypingUsers((prev) => [...prev, recipientId]);
      }
      setTimeout(() => {
        setTypingUsers((prev) => prev.filter((id) => id !== recipientId));
      }, 10000);
    });

    onEvent("user:stoppedTyping", ({ senderId }) => {
      setTypingUsers((prev) => prev.filter((id) => id !== senderId));
    });

    return () => {
      offEvent("user:typing");
      offEvent("user:stoppedTyping");
      disconnectSocket();
    };
  }, []);

  const senderId = Number(selectedUser?.userId);
  const recipientId = Number(myId);
  const handleTyping = () => {
    if (!isTyping) {
      setIsTyping(true);
      emitEvent("user:typing", { senderId, recipientId });
      console.log(senderId, "==>typing", recipientId);

      // Emit 'stoppedTyping' after a delay when user stops typing
      const timeout = setTimeout(() => {
        setIsTyping(false);
        emitEvent("user:stoppedTyping", { senderId, recipientId });
      }, 2000);

      return () => clearTimeout(timeout);
    }
  };

  const handleSendMessage = async () => {
    const messageData = {
      senderId: Number(selectedUser?.userId),
      recipientId: Number(myId),
      content: inputMessage,
      messageType: uploadPosterURL?.length > 0 ? "image" : "text",
      mediaUrl: uploadPosterURL[0],
    };

    try {
      emitEvent("chat:sendMessage", messageData);
      dispatch(setSendMessageText(messageData));

      

      setInputMessage("");
      setUploadProgress("");
      setUploadPosterURL("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleEditMessage = async () => {
    if (!editContent) {
      console.error("Cannot edit an empty message");
      return;
    }

    try {
      // Update the message via API
      const response = await chatting.editMessage(
        selectedUser?.userId,
        messageContent?.id,
        editContent
      );

      console.log("Message edited successfully", response);
      dispatch(setClickedEdit(false));
      // Clear the editContent after editing
      setEditContent("");
    } catch (error) {
      console.error("Failed to edit message", error);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("Please select a file first");
      return;
    }
    setSelectedFile(file);
    try {
      const url = await uploadFilesToS3([file], setUploadProgress);
      setUploadPosterURL(url);
    } catch (error) {
      console.error("File upload error:", error);
      setError("File upload failed. Please try again.");
    }
  };

  return (
    <>
      {!selectedUser.block ? (
        clickedEdit ? (
          <div className="p-1 white-bg footer">
            <div className="white-bg border-grey br-20 small-font d-flex flex-row py-2 px-2">
              <input
                type="text"
                value={editContent || ""} // Ensure value is not undefined
                placeholder="Edit message"
                className="all-none grey-text w-75"
                onChange={handleInputEdit}
              />
              <div className="d-flex flex-row w-25 align-items-center justify-content-around">
                <FcGallery className="font-30 mx-1" />
                <IoSend
                  className="font-30 grey-text"
                  onClick={handleEditMessage}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="p-1 white-bg footer">
            <div className="white-bg border-grey br-20 small-font d-flex flex-row py-2 px-2">
              <input
                type="text"
                value={inputMessage}
                placeholder={`${
                  selectedFile &&
                  uploadProgress &&
                  uploadProgress[selectedFile.name] === 100
                    ? "Image uploaded"
                    : "Type your message"
                }`}
                className="all-none grey-text w-75"
                onChange={handleInputMessage}
              />
              <div className="d-flex flex-row w-25 align-items-center justify-content-around">
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="file-upload"
                  onChange={handleFileChange}
                />
                <label htmlFor="file-upload">
                  <FcGallery className="font-30 mx-1" />
                </label>
                <IoSend
                  className="font-30 grey-text"
                  onClick={handleSendMessage}
                />
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="p-1 white-bg footer">
          <div className="text-center grey-text">
            You cannot send messages to this user as they are blocked.
          </div>
        </div>
      )}
    </>
  );
}

export default ChatFooter;
