import React, { useEffect, useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import CommonTable from "../../components/CommonTable";

import WithdrawPopup from "../popup/WithdrawPopup";
import { getWalletStatus, getWalletTable } from "../../api-folder/apiMethods";
import moment from "moment";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import AddAmountPopup from "./AddAmountPopup";

import {
  deleteUserDepositDetailsByID,
  deleteUserWithdrawDetailsByID,
} from "../../api-folder/apiMethods";

function Wallet() {
  const [activeButton, setActiveButton] = useState(0);

  const handleButtons = (index) => {
    setActiveButton(index);
  };
  const [withdraw, setWithdraw] = useState(false);
  const handleWithdrawPopupOpen = () => {
    setWithdraw(true);
  };

  const [addAmountPopup, setShowAddAmountPopup] = useState(false);
  const handleAddAmountPopup = () => {
    setShowAddAmountPopup(true);
  };

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectWithdrawId, setSelectedWithdrawId] = useState();
  const [selectDepositId, setSelectedDepositId] = useState();

  const handleShowDeletePopup = (type, id) => {
    setShowDeletePopup(true);
    if (type === "withdraw") {
      setSelectedWithdrawId(id);
      setSelectedDepositId(null);
    } else if (type === "deposit") {
      setSelectedDepositId(id);
      setSelectedWithdrawId(null);
    }
  };

  const buttons = ["Deposit", "Withdraw"];
  const [walletData, setWalletData] = useState([]);
  console.log(walletData, "walletDataaaaaaaaaaaaaa");
  const [ticketsWalletData, setTicketsWalletData] = useState([]);
console.log(ticketsWalletData,"ticketsWalletDatahgfcxdfghjgfvghjkgfg")
  const columns = [
    { key: "date_time", title: "Tnx Details" },
    { key: "balance", title: " Amount" },
    { key: "status", title: "Status" },
    { key: "view", title: "View" },
  ];

  const [showWithdrawPopup, setShowWithdrawPopup] = useState(false);
  const handleSettlement = () => {
    setShowWithdrawPopup(true);
  };

  const walletformattedData = walletData.map((item) => ({
    date_time: (
      <div className="password-clr font-16 fw-600 d-flex flex-column">
        <div>{item.date_time}</div>

        <div>{item.tnx_id}</div>
      </div>
    ),
    balance: (
      <div className={`${item?.type === "Deposit" ? "clr-green" : "clr-red"}`}>
        {item.amount}
      </div>
    ),
    status: (
      <div className="d-flex flex-column w-100 justify-content-center align-items-center">
        <div
          className={` py-1 px-2 br-10 font-16 ${
            item.status === "Approved"
              ? "green-bg clr-green"
              : item.status === "Pending"
              ? "clr-rejected pink-bg-reject"
              : "bg-pending clr-pending"
          }`}
        >
          {item.status}
        </div>
      </div>
    ),
    view: (
      <div className="d-flex flex-column w-100 justify-content-center align-items-center">
        <div className="py-1">
          <IoMdEye className="font-30 grye-text" />
        </div>
        <div className="py-1">
          <CiEdit className="font-30 grye-text" />
        </div>
        <div className="py-1">
          <RiDeleteBin6Line
            className="font-30 grye-text"
            onClick={() => handleShowDeletePopup("withdraw", item.withdraw_id)}
          />
        </div>
      </div>
    ),
  }));

  const ticketcolumns = [
    { key: "date_time", title: "Tnx Details" },
    { key: "amount", title: " Amount" },
    { key: "status", title: "Status" },
    { key: "view", title: "View" },
  ];

  const ticketformattedData = ticketsWalletData.map((item) => ({
    date_time: (
      <div className="password-clr font-16 fw-600 d-flex flex-column">
        <div>{item.date}</div>
        <div>{item.time}</div>
        <div>{item.tnx_id}</div>
      </div>
    ),
    amount: (
      <div className="clr-green line-height font-16 fw-600 d-flex flex-column">
        {item.amount}
      </div>
    ),
    status: (
      <div className="d-flex flex-column w-100 justify-content-center align-items-center">
        <div
          className={` py-1 px-2 br-10 font-16 ${
            item.status === "Approved"
              ? "green-bg clr-green"
              : item.status === "Pending"
              ? "clr-rejected pink-bg-reject"
              : "bg-pending clr-pending"
          }`}
        >
          {item.status}
        </div>
      </div>
    ),
    view: (
      <div className="d-flex flex-column w-100 justify-content-center align-items-center">
        <div className="py-1">
          <IoMdEye className="font-30 grye-text" />
        </div>
        <div className="py-1">
          <CiEdit className="font-30 grye-text" />
        </div>
        <div className="py-1">
          <RiDeleteBin6Line
            className="font-30 grye-text"
            onClick={() => handleShowDeletePopup("deposit", item.deposit_id)}
          />
        </div>
      </div>
    ),
  }));

  const [walletStatusDisplay, setWalletStatusDisplay] = useState([]);
  console.log(walletStatusDisplay, "walletStatusDisplayyyyyyyyyyyyyyy");

  const [userWalletWithdrawTable, setUserWalletWithdrawTable] = useState([]);
  console.log(userWalletWithdrawTable, "45678dfghjfghfbdbfgb");
  const walletStatus = () => {
    getWalletStatus()
      .then((response) => {
        if (response.status) {
          const data = response?.data || [];
          const formattedData = [
            {
              name: "Total Deposit",
              totalEarningsAgent: data.totalEarningsAgent,
            },
            {
              name: "Total Withdraw",
              totalEarningsAgent: data.totalWithdrawal,
            },
            {
              name: "Balance",
              totalEarningsAgent: data.balance,
              icon: "💰",
              withdraw: "Withdraw",
            },
          ];
          setWalletStatusDisplay(formattedData);
          console.log("Statusss", response);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log("error"));
  };
  useEffect(() => {
    walletStatus();
  }, []);

  //combine arrays

  const walletTable = () => {
    getWalletTable()
      .then((response) => {
        if (response.status === true) {
          setUserWalletWithdrawTable(response.data.withdraw);
          const combinedData = [
            ...response.data.deposit.map((item) => ({
              type: item.type,
              date_time: (
                <div>
                  {moment(item.time_date).format("DD-MM-YYYY")}
                  <br />
                  {moment(item.time_date).format("hh:mm A")}
                </div>
              ),
              tnx_id: item.tnx_id,
              amount: item.deposit_amount,
              view: item.view,
              // <HiOutlineEye className="icon-font" />,

              status: item.status,
            })),
          ];
          setWalletData(combinedData);
          console.log("tableee", response);
        } else {
          console.error("Failed to fetch wallet data.");
        }
      })
      .catch((error) =>
        console.error("Error fetching wallet data:", error.message)
      );
  };
  useEffect(() => {
    walletTable();
  }, []);

  const ticketsWalletTable = () => {
    getWalletTable()
      .then((response) => {
        if (response.status) {
          const ticketsData = [
            ...response.data.withdraw.map((item) => ({
              date: moment(item.time_date).format("DD-MM-YYYY"),
              time: moment(item.time_date).format("hh:mm A"),
              type: item.type,
              tnx_id: item.tnx_id,
              amount: item.amount,
              view: item.view,

              status: item.status,
            })),
          ];
          setTicketsWalletData(ticketsData);
          console.log("ticketsWalletData", ticketsWalletData);
        } else {
          console.error("Failed to fetch wallet data.");
        }
      })
      .catch((error) =>
        console.error("Error fetching wallet data:", error.message)
      );
  };
  useEffect(() => {
    ticketsWalletTable();
  }, []);

  const deleteUserWithdrawData = () => {
    deleteUserWithdrawDetailsByID(selectWithdrawId)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setShowDeletePopup(false);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    deleteUserWithdrawData();
  }, []);

  const deleteUserdepositData = () => {
    deleteUserDepositDetailsByID(selectDepositId)
      .then((response) => {
        if (response.status === true) {
          console.log(response.data, "Response For Tickets");
          setShowDeletePopup(false);
        } else {
          console.log("Some Error Occured");
        }
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    deleteUserdepositData();
  }, []);

  return (
    <div className="input-bg homecontent-sec pos-relative pb-20p">
      <div className="py-2 border-bottom-grey">
        <div className="password-clr font-20 fw-600 my-2 px-2">Settlement</div>
        <div className="d-flex justify-content-start align-items-center">
          {buttons.map((btn, index) => (
            <div
              key={index}
              className={
                activeButton === index
                  ? "w-40 red-bg br-20 font-18 clr-white mr-1 fw-600 mx-1 text-center py-2"
                  : "w-40 white-bg password-clr font-18 border-grey-not br-20 py-2 fw-600 mx-1 text-center"
              }
              onClick={() => handleButtons(index)}
            >
              {btn}
            </div>
          ))}
        </div>
      </div>
      <div className="p-2">
        {walletStatusDisplay.length > 0 &&
          walletStatusDisplay.map((item, index) => (
            <div
              key={index}
              className="my-2 white-bg br-10 d-flex flex-row align-items-center fw-bold p-2 justify-content-between custom-box-shadow"
            >
              <div>
                <div className="font-24 fw-bold">
                  {item.totalEarningsAgent || 0}
                </div>
                <div className="font-16">{item.name}</div>
              </div>
              {index === 2 && (
                <div className="flex-column flex-center">
                  {activeButton === 0 ? (
                    <div
                      className="px-2 py-2 rounded-pill small-font pink-bg clr-white cursor-pointer"
                      onClick={handleAddAmountPopup}
                    >
                      Add Amount
                    </div>
                  ) : (
                    <>
                      <div
                        className="px-2 py-2 rounded-pill small-font pink-bg clr-white cursor-pointer"
                        onClick={handleSettlement}
                      >
                        Withdraw
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
      </div>
      {activeButton == 0 && (
        <CommonTable
          data={walletformattedData}
          columns={columns}
          itemsPerPage={5}
        />
      )}
      {activeButton == 1 && (
        <CommonTable
          data={ticketformattedData}
          columns={ticketcolumns}
          itemsPerPage={5}
        />
      )}

      <WithdrawPopup
        showWithdrawPopup={showWithdrawPopup}
        setShowWithdrawPopup={setShowWithdrawPopup}
        balanceAmt={walletStatusDisplay}
      />
      <AddAmountPopup
        addAmountPopup={addAmountPopup}
        setShowAddAmountPopup={setShowAddAmountPopup}
        userWalletWithdrawTable={userWalletWithdrawTable}
      />
    </div>
  );
}

export default Wallet;
