import apiRequest from "./apiRequest";

const loginUser = (data) => {
  return apiRequest("loginUser", data);
};
const signUpUser = (data) => {
  return apiRequest("signUpUser", data);
};
const tourCategory = (data) => {
  return apiRequest("tourCategory", data);
};
const resetPassword = (data) => {
  return apiRequest("resetPassword", data);
};
const userEditProfile = (data) => {
  return apiRequest("userEditProfile", data);
};
const upcomingMyTrips = (data) => {
  return apiRequest("upcomingMyTrips", data);
};
const completedMyTrips = (data) => {
  return apiRequest("completedMyTrips", data);
};
const TripTickets = (data) => {
  return apiRequest("TripTickets", data);
};
const getUserDetails = (data) => {
  return apiRequest("getUserDetails", data);
};
const postForgotPassword = (data) => {
  return apiRequest("postForgotPassword", data);
};
const postVerifyOTP = (data) => {
  return apiRequest("postVerifyOTP", data);
};
const updatePassword = (data) => {
  return apiRequest("updatePassword", data);
};
const getPublishedTours = (data) => {
  return apiRequest("getPublishedTours", data);
};
const getPublishedTourById = (id) => {
  return apiRequest("getPublishedTourById", null, id);
};
const getBankDetailsByTourId = (tour_id) => {
  return apiRequest("getBankDetailsByTourId", null, tour_id);
};
const getTravelReports = (data) => {
  return apiRequest("getTravelReports", data);
}
const getBestDestination = (data) => {
  return apiRequest("getBestDestination", data);
};
const getTravelReportDetailsSummaryById = (id) => {
  return apiRequest("getTravelReportDetailsSummaryById", null, id);
};
const getTravelReportDetailsById = (id) => {
  return apiRequest("getTravelReportDetailsById", null, id);
};

const getDownlineTravelReportsById = (id) => {
  return apiRequest("getDownlineTravelReportsById", null, id);
};

const getAllMemories = (queryParams) => {
  return apiRequest("getAllMemories", null, null, queryParams);
};

const AddCommentsById = (id, data) => {
  return apiRequest("AddCommentsById", data, id);
};
const EditCommentsById = (id, data) => {
  return apiRequest("EditCommentsById", data, id);
};

const getMemoryDetailsById = (id, data) => {
  return apiRequest("getMemoryDetailsById", data, id);
};
const DeletecommentById = (id,data) => {
  return apiRequest("DeletecommentById", data, id);
};
const PostLike = (data) => {
  return apiRequest("PostLike", data);
};

const disLike = (id) => {
  return apiRequest("disLike", null, id);
};

const replyComment = (id, data) => {
  return apiRequest("replyComment", data, id);
};
const uploadImages=(data)=>{
  return apiRequest("uploadImages", data)
}

// get tour details
const getAllTours =(data) =>{
  return apiRequest("getAllTours",data)
}

const getItineraryTours = (id,data) =>{
  return apiRequest("getItineraryTours",id,data)
}

const getApprovedTours = (id, data) => {
  return apiRequest("getApprovedTours", data, id);
};

const getOngoingTours = (id, data) => {
  return apiRequest("getOngoingTours", data, id);
};

const getRejectedTours = (id, data) => {
  return apiRequest("getRejectedTours", data, id);
};

const getWalletTable=(data) =>{
  return apiRequest("getWalletTable",data)
  }
  
  const getWalletStatus=(data)=>{
    return apiRequest("getWalletStatus", data)
  }

  const postWithdraw = (data)=>{
    return apiRequest("postWithdraw",data)
  }

  const createUserDeposit = (data) => apiRequest("createUserDeposit", data);
const getAllDepositBankings = (data) =>
  apiRequest("getAllDepositBankings", data);


const deleteUserDepositDetailsByID = (id, data) => {
  return apiRequest("deleteUserDepositDetailsByID", data, id);
};


const deleteUserWithdrawDetailsByID = (id, data) => {
  return apiRequest("deleteUserWithdrawDetailsByID", data, id);
};


export {
  loginUser,
  signUpUser,
  tourCategory,
  resetPassword,
  userEditProfile,
  upcomingMyTrips,
  completedMyTrips,
  TripTickets,
  getUserDetails,
  postForgotPassword,
  postVerifyOTP,
  updatePassword,
  getPublishedTours,
  getPublishedTourById,
  getBankDetailsByTourId,
  getTravelReports,
  getDownlineTravelReportsById,
  getAllMemories,
  AddCommentsById,
  EditCommentsById,
  getMemoryDetailsById,
  DeletecommentById,
  PostLike,
  disLike,
  replyComment,
  getBestDestination,
  getTravelReportDetailsSummaryById,
  getTravelReportDetailsById,
  uploadImages,
  getAllTours,
  getItineraryTours,
  getApprovedTours,
  getOngoingTours,
  getRejectedTours,
  getWalletTable,
  getWalletStatus,
  postWithdraw,
  createUserDeposit,
  getAllDepositBankings,
  deleteUserDepositDetailsByID,
  deleteUserWithdrawDetailsByID
}
