import { IonModal } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { postWithdraw, uploadImages } from "../../api-folder/apiMethods";
const WithdrawPopup = ({
  showWithdrawPopup,
  setShowWithdrawPopup,
  balanceAmt,
}) => {
  console.log(balanceAmt, "balanceAmt");

  const userDetailsData = useSelector((state) => state.userDetailsData);
  console.log(userDetailsData, "userDetailsData");

  const totalBalance = balanceAmt?.filter((item) => item?.name === "Balance");
  console.log(totalBalance, "totalBalance======");

  const [qrCodeImage, setQrCodeImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [uploadProgress, setUploadProgress] = useState(null);
  const [paymentTypeId, setPaymentTypeId] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState();

  const handleQrCodeUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("Please select a file first");
      return;
    }
    setSelectedFile(file);
    try {
      const url = await ([file], setUploadProgress);
      handleQrcodeSubmit(url);
    } catch (error) {
      console.error("File upload error:", error);
      setError("File upload failed. Please try again.");
    }
  };

  const handleQrcodeSubmit = (url) => {
    uploadImages({ path: url, type: "image" })
      .then((response) => {
        if (response?.status === true) {
          const paymentTypeIds = response.data.map((item) => item.id);
          setPaymentTypeId(paymentTypeIds);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => setError(error?.message));
  };

  const [amount, setAmount] = useState("");

  const [withdrawBalance, setWithdrawBalance] = useState(
    totalBalance?.[0]?.totalEarningsAgent || 0
  );
  console.log(
    totalBalance?.[0]?.totalEarningsAgent,
    "totalBalance?.[0]?.totalEarningsAgentttttt"
  );
  const [error, setError] = useState("");
  const [isWithdrawDisabled, setIsWithdrawDisabled] = useState(false);
  const [selectedPaymentGateway, setSelectedPaymentGateway] =
    useState("Phone Pe");
  const [bankDetails, setBankDetails] = useState("");

  useEffect(() => {
    setWithdrawBalance(totalBalance?.[0]?.totalEarningsAgent || 0);
  }, [totalBalance?.[0]?.totalEarningsAgent]);

  const handleAmountChange = (e) => {
    const value = e.target.value;

    if (value === "") {
      setAmount("");
      setError("");
      setWithdrawBalance(totalBalance?.[0]?.totalEarningsAgent || 0);
      setIsWithdrawDisabled(false);
      return;
    }

    const enteredAmount = parseFloat(value);

    if (enteredAmount < 1000) {
      setError("The amount must be at least 1000.");
      setIsWithdrawDisabled(false);
      setWithdrawBalance(totalBalance?.[0]?.totalEarningsAgent || 0);
    } else if (enteredAmount > (totalBalance?.[0]?.totalEarningsAgent || 0)) {
      setError("Recharge your wallet.");
      setIsWithdrawDisabled(true);
    } else {
      setError("");
      setIsWithdrawDisabled(false);
      const updatedWithdrawBalance =
        (totalBalance?.[0]?.totalEarningsAgent || 0) - enteredAmount;
      setWithdrawBalance(updatedWithdrawBalance);
    }

    setAmount(value);
  };

  const handlePaymentGatewayChange = (e) => {
    setSelectedPaymentGateway(e.target.value);
  };

  const handleBankDetailsChange = (e) => {
    setBankDetails(e.target.value);
  };

  const userWithdraw = () => {
    if (!amount || parseFloat(amount) < 1000 || error) {
      console.log("Please fix the errors before submitting.");
      return;
    }

    let payload = {
      withdrawAmount: parseFloat(amount),
      balance_amount: totalBalance?.[0]?.totalEarningsAgent,
      user_id: userDetailsData.id,
      payment_mode: selectedPaymentGateway.toLowerCase(),
    };

    if (selectedPaymentGateway === "QR Code") {
      payload.upload_id = Number(paymentTypeId);
    } else {
      payload.bank_details = bankDetails;
    }

    postWithdraw(payload)
      .then((response) => {
        if (response.status === true) {
          setShowWithdrawPopup(false);
          setTimeout(() => {
            setShowSuccessPopup(true);
          }, 1000);
        } else {
          console.log("Error Is Happening");
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    userWithdraw();
  }, []);

  return (
    <IonModal isOpen={showWithdrawPopup} className="custom-modal">
      <div className="white-bg br-7">
        <div className="text-end">
          <RxCross2
            className="font-30 py-1 px-1"
            onClick={() => setShowWithdrawPopup(false)}
          />
        </div>
        <div className="font-22 fw-bold text-center w-100 password-clr ">
          Withdraw{" "}
        </div>
        <div className="p-1 h-70vh">
          <div className="d-flex flex-column my-1">
            <div className="password-clr fw-600 font-16 my-2">
              Role <span className="clr-red">*</span>
            </div>
            <input
              type="text"
              //className="input-box1 br-10 p-2 w-fill fw-500"
              className="fw-600 input-bg bg-none br-10 py-2 w-100 px-2 my-1"
              value={userDetailsData?.role?.code}
              readOnly
            />
          </div>
          <div className="d-flex flex-column my-1">
            <div className="password-clr fw-600 font-16 my-1">
              Name <span className="clr-red">*</span>
            </div>
            <input
              className="fw-600 input-bg bg-none br-10 py-2 w-100 px-2 my-1"
              type="text"
              value={userDetailsData?.name}
              readOnly
            />

            <div className="password-clr fw-600 font-16 my-1">
              Wallet Amount<span className="clr-red">*</span>
            </div>
            <input
              className="fw-600 input-bg bg-none br-10 py-2 w-100 px-2 my-1"
              type="text"
              placeholder="Amount"
              value={totalBalance?.[0]?.totalEarningsAgent || 0}
              readOnly
            />
            <label className="mb-1">Balance Amount</label>
            <input
              type="text"
              placeholder="Balance"
              className="fw-600 input-bg bg-none br-10 py-2 w-100 px-2 my-1"
              value={withdrawBalance}
              readOnly={isWithdrawDisabled}
            />
          </div>
          <div className="d-flex flex-column my-1">
            <div className="password-clr fw-600 font-16 my-2">
              Withdraw Amount<span className="clr-red">*</span>
            </div>

            <input
              type="number"  
              placeholder="Amount"
              className="fw-600 input-bg bg-none br-10 py-2 w-100 px-2 my-1"
              value={amount}
              onChange={handleAmountChange}
              min={1000}
            />
            {error && <p className="small -font error-text">*{error}</p>}

            {/* <select className="input-bg bg-none br-10 py-2 fw-600 grey-text w-100 px-2">
              <option>Select Role</option>
            </select> */}
          </div>
          <div className="d-flex flex-column my-1">
            <div className="password-clr fw-600 font-16 my-2">
              Payment Mode <span className="clr-red">*</span>
            </div>
            <select
              value={selectedPaymentGateway}
              onChange={handlePaymentGatewayChange}
              className="input-bg bg-none br-10 py-2 font-14  fw-600 grey-text w-100 px-2"
            >
              <option value="Phone Pe">Phone Pay</option>
              <option value="Google Pay">Google Pay</option>
              <option value="Paytm">Paytm</option>
              <option value="NEFT">NEFT</option>
              <option value="QR Code">QR Code</option>
            </select>
          </div>
          {selectedPaymentGateway === "QR Code" && (
            <>
              <div className="d-flex flex-column my-1">
                <div className="password-clr fw-600 font-16 my-2">
                  Upload QR Code<span className="clr-red">*</span>
                </div>
                <input
                  className="input-bg bg-none br-10 py-2 font-14  fw-600 grey-text w-100 px-2"
                  type="file"
                  id="fileUpload"
                  onChange={handleQrCodeUpload}
                ></input>
              </div>
              <div className="d-flex flex-column my-1">
                <label
                  htmlFor="fileUpload"
                  className="custom-file-upload input-bg px-2 py-1 small-font"
                >
                  <span className="small-font">Upload File</span>
                  <span className="upload-icon">📤</span>
                </label>
                {selectedFile && (
                  <div className="mt-2">
                    <p>Selected File: {selectedFile.name}</p>
                  </div>
                )}
                {error && (
                  <div className="text-danger mt-2">
                    <p>{error}</p>
                  </div>
                )}
              </div>

              {/* <div className="password-clr fw-600 font-16 my-2">
                  Name<span className="clr-red">*</span>
                </div>
                <input
                  className="input-bg bg-none br-10 py-2 font-14  fw-600 grey-text w-100 px-2"
                  type="text"
                  placeholder="Enter"
                ></input>
              </div> */}
            </>
          )}
          {selectedPaymentGateway !== "QR Code" && (
            <>
              <div className="d-flex flex-column my-1">
                <div className="password-clr fw-600 font-16 my-2">
                  UPI/Bank Details<span className="clr-red">*</span>
                </div>
                <input
                  className="input-bg bg-none br-10 py-2 font-14  fw-600 grey-text w-100 px-2"
                  value={bankDetails}
                  onChange={handleBankDetailsChange}
                  placeholder="Enter your UPI or Bank details here"
                ></input>
              </div>
              <div className="d-flex flex-column my-1">
                <div className="password-clr fw-600 font-16 my-2">
                  Bank Name<span className="clr-red">*</span>
                </div>
                <input
                  className="input-bg bg-none br-10 py-2 font-14  fw-600 grey-text w-100 px-2"
                  type="text"
                  placeholder="Enter"
                ></input>
              </div>
              <div className="d-flex flex-column my-1">
                <div className="password-clr fw-600 font-16 my-2">
                  Account Number<span className="clr-red">*</span>
                </div>
                <input
                  className="input-bg bg-none br-10 py-2 font-14  fw-600 grey-text w-100 px-2"
                  type="text"
                  placeholder="Enter"
                ></input>
              </div>
              <div className="d-flex flex-column my-1">
                <div className="password-clr fw-600 font-16 my-2">
                  IFSC Code<span className="clr-red">*</span>
                </div>
                <input
                  className="input-bg bg-none br-10 py-2 font-14  fw-600 grey-text w-100 px-2"
                  type="text"
                  placeholder="Enter"
                ></input>
              </div>
            </>
          )}

          {qrCodeImage && selectedPaymentGateway === "QR Code" && (
            <div className="flex-center mt-2">
              <img src={qrCodeImage} alt="QR Code" className="qr-code-img" />
            </div>
          )}

          <div
            className="pink-bg br-20 clr-white mt-2 fw-bold py-2 w-100 text-center"
            onClick={() => userWithdraw()}
          >
            Submit
          </div>
        </div>
      </div>
    </IonModal>
  );
};

export default WithdrawPopup;
