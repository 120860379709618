import React, { useEffect, useState } from "react";
import { Images } from "../../images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoLocationOutline } from "react-icons/io5";
import { FcLike } from "react-icons/fc";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import { IonRow, IonCol, IonImg } from "@ionic/react";
import { getPublishedTours } from "../../api-folder/apiMethods";

function CasinoTour() {
  const navigate = useNavigate();
  const { tourCategory } = useParams();
  const tourCategoryHeading = tourCategory
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
  const [publishedToursData, setPublishedTourData] = useState([]);
  const [events, setEvents] = useState([]);
  const [activeEventIndex, setActiveEventIndex] = useState(0);
  const [activeEvent, setActiveEvent] = useState(null);

  const getAllPublishedTours = () => {
    getPublishedTours({})
      .then((response) => {
        if (response?.status === true) {
          const filteredTours = response?.data?.filter(
            (tour) => tour.slug.toLowerCase() === tourCategory.toLowerCase()
          );
          setPublishedTourData(filteredTours || []);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  useEffect(() => {
    getAllPublishedTours();
  }, [tourCategory]);

  useEffect(() => {
    if (publishedToursData.length > 0) {
      const mappedEvents = publishedToursData.map((tour) => ({
        image: Images.CardImage,
        title: tour.tour_title,
        date: new Date(tour.schedule_from).toLocaleDateString("en-GB"),
        location: tour.tour_location,
        description: tour.quotations,
        path: `/tour/${tourCategory}/${tour.tour_id}`,
      }));
      setEvents(mappedEvents);
      setActiveEvent(mappedEvents[0]);
    } else {
      setEvents([]);
      setActiveEvent(null);
    }
  }, [publishedToursData]);

  const settings = {
    vertical: true,
    slidesToShow: Math.min(events.length, 3),
    slidesToScroll: 1,
    infinite: events.length > 1,
    arrows: false,
    dots: false,
    autoplay: events.length > 1,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    afterChange: (current) => {
      setActiveEventIndex(current);
      setActiveEvent(events[current % events.length]);
    },
  };

  return (
    <div className="input-bg p-2 homecontent-sec">
      <div className="white-bg px-2 py-1 br-10">
        <div className="font-20 password-clr my-1 fw-700">
          {tourCategoryHeading}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <img src={Images.CasinoBanner} alt={tourCategory} />
        </div>
      </div>
      <div>
        <div className="font-20 password-clr my-1 fw-700">
          Upcoming {tourCategoryHeading}
        </div>
        <div className="font-14 grey-text">
          {events.length} destinations found
        </div>
        {events.length > 0 ? (
          <div
            className="d-flex flex-column"
            style={{ maxHeight: "35vh", overflow: "hidden" }}
          >
            <div className="hide-scrollbar">
              <Slider {...settings}>
                {events.map((event, index) => (
                  <div
                    key={index}
                    className={`white-bg br-10 p-1 mt-2 boxShadow ${
                      index === activeEventIndex ? "active-event" : ""
                    }`}
                    onClick={() => navigate(event?.path)}
                  >
                    <IonRow>
                      <IonCol size="4">
                        <IonImg src={event.image} className="w-100 h-10vh" />
                      </IonCol>
                      <IonCol size="8">
                        <div className="d-flex flex-column justify-content-between">
                          <div className="font-18 fw-500">{event.title}</div>
                          <div className="d-flex flex-row align-items-center mt-1">
                            <FaRegCalendarAlt className="font-18 mr-1" />
                            <div className="font-14 mr-3">{event.date}</div>
                            <IoLocationOutline className="font-18 mr-1" />
                            <div className="font-14">{event.location}</div>
                          </div>
                          <div className="d-flex flex-row align-items-center mt-1">
                            <FcLike className="font-24 mr-1" />
                            <div className="font-14">I am Interested</div>
                          </div>
                        </div>
                      </IonCol>
                    </IonRow>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        ) : (
          <div>No tours available</div>
        )}
      </div>
      {activeEvent && (
        <div>
          <h4 className="fw-700 password-clr">
            Tour Name: <span className="clr-pink">{activeEvent.title}</span>
          </h4>
          <div className="font-14 fw-500 grey-text">
            {activeEvent.description || "Tour Message"}
          </div>
          <div className="w-40 pink-bg py-2 br-20 clr-white fw-700 text-center my-2">
            Book Now
          </div>
        </div>
      )}
    </div>
  );
}

export default CasinoTour;
