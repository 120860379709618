import { createStore } from "redux";

const initialState = {
  userDetailsData: [],
  uploadPosterId: null,
  tourCategoryData: [],
  travelReportsData: {},
  selectedUser: {},
  changeHeader:null,
  clickedEdit:null,
  messageContent:{},
  selectedMessageId:"",
  sendMessageText:""
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER_DETAILS_DATA":
      return {
        ...state,
        userDetailsData: action.payload,
      };
    case "SET_UPLOAD_POSTER_ID":
      return {
        ...state,
        uploadPosterId: action.payload,
      };
    case "SET_TOUR_CATEGORY_DATA":
      return {
        ...state,
        tourCategoryData: action.payload,
      };
    case "SET_TRAVEL_REPORTS_DATA":
      return {
        ...state,
        travelReportsData: action.payload,
      };
      case "SET_SELECTED_USER":
        return {
          ...state,
          selectedUser: action.payload,
        };
        case "SET_CHANGE_HEADER":
          return {
            ...state,
            changeHeader: action.payload,
          };
          case "SET_CLICKED_EDIT":
          return {
            ...state,
            clickedEdit: action.payload,
          };
          case "SET_MESSAGE_CONTENT":
            return {
              ...state,
              messageContent: action.payload,
            };
            case "SET_MESSAGE_ID":
              return {
                ...state,
                selectedMessageId: action.payload,
              };
              case "SET_MESSAGE_TEXT":
                return {
                  ...state,
                  sendMessageText: action.payload,
                };
              

    default:
      return state;
  }
};

const store = createStore(authReducer);

export default store;
