import { IonCol, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { IoLocationOutline } from "react-icons/io5";
import { FaRegCalendarAlt } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PiAirplaneTakeoffLight } from "react-icons/pi";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { PiHandCoinsLight } from "react-icons/pi";
import { useNavigate } from "react-router";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useDispatch } from "react-redux";
import { setTourCategoryData } from "./../../redux/action";
import { getTravelReports} from '../../api-folder/apiMethods';
import { setUserDetailsData } from "./../../redux/action";
import {
  getBestDestination,
  getTravelReportDetailsSummaryById,
  tourCategory,
  getUserDetails,
} from "../../api-folder/apiMethods";
// import { Images } from "../images";
// import moment from "moment";
import { Images } from "../../images";

function HomePage() {
  const role_id = localStorage?.getItem("myid");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollOptions = {
    type: "loop",
    padding: "20%",
  };
  const [categories, setCategories] = useState([]);
  const [bestDestinationData, setBestDestinationData] = useState([]);
  const [travelReportsSummary, setTravelReportsSummary] = useState();

  useEffect(() => {
    // Fetch tour categories
    tourCategory()
      .then((response) => {
        if (response?.status) {
          console.log(response?.data);
          setCategories(response.data.category);
          dispatch(setTourCategoryData(response.data.category));
        } else {
          console.error("Failed to fetch categories");
        }
      })
      .catch((error) => console.error(error?.message));

    // Fetch best destinations
    getBestDestination()
      .then((response) => {
        if (response?.status) {
          console.log(response, "desty")
          setBestDestinationData(response.data || []);
        }
      })
      .catch((error) => console.error(error?.message));

    // Fetch user details
    getUserDetails()
      .then((response) => {
        if (response?.status) {
          dispatch(setUserDetailsData(response?.data));
        }
      })
      .catch((error) => console.error(error?.message));

    // Fetch travel reports by Id
    getTravelReportDetailsSummaryById(role_id)
      .then((response) => {
        if (response?.status === true) {
          console.log(response)
          setTravelReportsSummary(response?.data);
        }
      })
      .catch((error) => console.log(error?.message));
  }, [role_id]);

  console.log(bestDestinationData, "===>bestDestinationData");
  console.log(categories,"===>categories");
  


   // get travel reports
   const [travelReports, setTravelReports] = useState([]);
   console.log(travelReports, "==travel reports");
 
   const fetchTravelReports = () => {
     getTravelReports({})
       .then((response) => {
         console.log(response," travel reports response")
         if (response?.status === true) {
           setTravelReports(response.data);
         } else {
           console.log("error");
         }
       })
       .catch((error) => console.log(error.message));
   };
 
   useEffect(() => {
     fetchTravelReports();
   }, []);

  return (
    <div className="input-bg p-2 homecontent-sec pos-relative">
      <div className="white-bg br-5">
        <div className="password-clr fw-bold font-18 p-2">
          Coming Most Popular Tours
        </div>
        <Splide aria-label="My Favorite Images" options={scrollOptions}>
          {categories.map((item, index) => (
            <SplideSlide
              key={index}
              onClick={() => navigate(`/tours/${item.slug}`)}
            >
              <div className="white-bg p-3 br-20">
                <img
                  src={Images.CasinoTourImg}
                  className="w-100 h-15vh"
                  alt={`${item.category_name} Banner`}
                />
                <div className="password-clr font-18 p-1 fw-bold">
                  {item.category_name}
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
      <div className="p-2">
        <h3 className="fw-bold  font-18 clr-black mt-2">Activity Summary</h3>
        <div className="white-bg br-10 p-2 d-flex flex-row align-items-center my-1">
          <div className="br-50 p-2 w-17 d-flex justify-conten-center pink-bg mr-1">
            <PiAirplaneTakeoffLight className="font-40 clr-white" />
          </div>
          <div className="w-90 d-flex flex-column line-height">
            <div className="font-24 fw-bold ">
              {travelReportsSummary?.totalCount}
            </div>
            <div className="password-clr font-16  ">
              Total Travel Planned
            </div>
          </div>
        </div>
        <div className="white-bg br-10 p-2 d-flex flex-row align-items-center my-1">
          <div className="br-50 p-2 w-17 d-flex justify-conten-center pink-bg mr-1">
            <HiOutlineUserGroup className="font-40 clr-white" />
          </div>
          <div className="w-90 d-flex flex-column justify-content-start line-height">
            <div className="d-flex flex-row w-100 justify-content-between">
              <div className="d-flex flex-column text-center">
                <div className="font-12 fw-600 font-12 green-bg password-clr text-center br-10 px-2">
                  Admin
                </div>
                <div className="font-24 fw-bold">
                  {travelReportsSummary?.downlinesCount?.marcketingTeamCount}
                </div>
              </div>
              <div className="d-flex flex-column text-center">
                <div className="font-12 fw-600 font-12 sky-bg password-clr text-center br-10 px-2">
                  Customer
                </div>
                <div className="font-24 fw-bold">
                  {travelReportsSummary?.downlinesCount?.usersCount}
                </div>
              </div>{" "}
            </div>
            <div className="password-clr font-16 fw-bold">
              Total Team Members
            </div>
          </div>
        </div>
        <div className="white-bg br-10 p-2 d-flex flex-row align-items-center my-1">
          <div className="br-50 p-2 w-17 d-flex justify-conten-center pink-bg mr-1">
            <HiOutlineUserGroup className="font-40 clr-white" />
          </div>
          <div className="w-90 d-flex flex-column line-height">
            <div className="font-24 fw-bold ">
              {travelReportsSummary?.bookingsCount}
            </div>
            <div className="password-clr font-16 fw-bold">
              Total Travel Customer
            </div>
          </div>
        </div>
        <div className="white-bg br-10 p-2 d-flex flex-row align-items-center my-1">
          <div className="br-50 p-2 w-17 d-flex justify-conten-center pink-bg mr-1">
            <PiHandCoinsLight className="font-40 clr-white" />
          </div>
          <div className="w-90 d-flex flex-column line-height">
            <div className="font-24 fw-bold ">
              {travelReportsSummary?.totalEarnings}
            </div>
            <div className="password-clr font-16 fw-bold">Total Earning</div>
          </div>
        </div>
      </div>
      <div>
        <h3 className="fw-bold  font-18 clr-black">Best Destination</h3>
        <div className="horizontal-scroll-content">
          {bestDestinationData?.length > 0 ? (
            <>
              {bestDestinationData.map((item, index) => (
                <div className="white-bg br-10 p-2 m-1 w-100" key={index}>
                  <IonRow>
                    <IonCol>
                      <iframe
                        width="100%"
                        height="100"
                        src="https://www.youtube.com/embed/ahy5o5nT4oI?si=SMZfKW9SD6vtSiR_"
                      />
                      <div className="d-flex flex-column justify-content-between">
                        <div
                          className="font-18 fw-600"
                          onClick={() => navigate(`/tours/${item.slug}`)}
                        >
                          {item?.tour_title}
                        </div>
                        <div className="d-flex my-1">
                          <div className="d-flex flex-row align-items-center mr-2">
                            <FaRegCalendarAlt className="font-16" />
                            <div className="font-14 ml-1 white-space">
                              {/* {moment(item?.schedule_from).format("DD-MM-YYYY")} */}
                            </div>
                          </div>
                          <div className="d-flex flex-row align-items-center">
                            <IoLocationOutline className="font-16" />
                            <div className="font-14 ml-1 max-width">
                              {item?.tour_location}
                            </div>
                          </div>
                        </div>
                        <div
                          className="w-fit white-space red-bg clr-white py-1 px-2 br-20 fw-600"
                          onClick={() => navigate(`/tours/${1}`)}
                        >
                          Book Now
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              ))}
            </>
          ) : (
            <h6 className="fw-500">No Tours Available</h6>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
