import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../images";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { IonCol, IonModal, IonRow } from "@ionic/react";
import { useNavigate } from "react-router";
import { FaHeart } from "react-icons/fa6";
import { FaCommentDots } from "react-icons/fa6";
import {
  getAllMemories,
  PostLike,
  getMemoryDetailsById,
  disLike,
} from "../../api-folder/apiMethods";
import moment from "moment/moment";
import { IoClose } from "react-icons/io5";

function Memories() {
  const navigate = useNavigate();

  const formatDateTime = (time) => {
    const dataObj = new Date(time);
    const formatDate = dataObj.toLocaleDateString("en-GB");
    const formatTime = dataObj.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formatDate}, ${formatTime}`;
  };

  const Buttons = ["All Photos", "Latest", "Popular"];
  const [activeButtons, setActiveButtons] = useState(0);
  const handleButtons = (index) => {
    setActiveButtons(index);
    if (index === 1) {
      fetchAlMemories({ latest: "latest" });
    } else if (index === 2) {
      fetchAlMemories({ latest: "popular" });
    } else {
      fetchAlMemories();
    }
  };

  const iframeRef = useRef();
  const [allMemories, setAllMemories] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  console.log(allMemories, "allMemories===");
  const fetchAlMemories = (latest = {}) => {
    getAllMemories({ latest })
      .then((response) => {
        if (response.status === true) {
          console.log(response, "resposne", latest);
          setAllMemories(response?.data?.records);
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error?.message));
  };

  useEffect(() => {
    fetchAlMemories();
  }, []);

  useEffect(() => {
    const imgData = [];
    const videosData = [];

    allMemories.forEach((memory) => {
      if (memory.type === "image") {
        imgData.push(memory);
      } else if (memory.type === "video") {
        videosData.push(memory);
      }
    });
    setPhotos(imgData);
    setVideos(videosData);
  }, [allMemories]);

  const id = allMemories?.memory_id;
  const [memoryData, setMemoryData] = useState([]);
  console.log(memoryData, "memoryData");
  const fetchMemorydetails = () => {
    getMemoryDetailsById(id)
      .then((response) => {
        if (response.status === true) {
          console.log(response, "====");
          setMemoryData(response?.data);
        }
      })
      .catch((error) => console.log(error?.message));
  };
  useEffect(() => {
    fetchMemorydetails();
  }, [id]);

  const [likedStatus, setLikedStatus] = useState({});
  useEffect(() => {
    const initialLikeStatus = {};
    photos.forEach((photo) => {
      initialLikeStatus[photo.memory_id] = !!photo.like_id;
    });
    setLikedStatus(initialLikeStatus);
  }, [photos]);

  const handleLikes = (memoryId, likedId) => {
    if (likedStatus[memoryId]) {
      disLike(likedId)
        .then((response) => {
          if (response.status === true) {
            console.log(response, "response dislike");
            setLikedStatus((prevStatus) => ({
              ...prevStatus,
              [memoryId]: false,
            }));
            setPhotos((prevPhotos) =>
              prevPhotos.map((photo) =>
                photo.memory_id === memoryId
                  ? {
                      ...photo,
                      likes_count: photo.total_likes_count - 1,
                      like_id: null,
                    }
                  : photo
              )
            );
            fetchAlMemories();
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error?.message));
    } else {
      PostLike({ memories_id: memoryId })
        .then((response) => {
          if (response.status === true) {
            console.log(response, "liked successfully");
            setLikedStatus((prevStatus) => ({
              ...prevStatus,
              [memoryId]: true,
            }));
            setPhotos((prevPhotos) =>
              prevPhotos.map((photo) =>
                photo.memory_id === memoryId
                  ? {
                      ...photo,
                      likes_count: photo.total_likes_count + 1,
                      like_id: response?.data?.like_id,
                    }
                  : photo
              )
            );
            console.log(response, "post likes");
            fetchAlMemories();
          } else {
            console.log("error");
          }
        })
        .catch((error) => console.log(error.message));
    }
  };

  const [isVideo, setIsVideo] = useState(null);
  const [videoPlayModal, setVideoPlayModal] = useState(false);
  const handleVideo = (videoPath) => {
    setIsVideo(videoPath);
  };

  const videoModal = () => {
    setVideoPlayModal(true);
  };

  return (
    <div className="input-bg homecontent-sec">
      <div className="px-2 py-1">
        <div className="d-flex justify-content-between flex-row align-items-center my-1">
          <div className="fw-bold font-20">Tour Videos</div>
        </div>
        <div className="d-flex flex-column">
          <div className="white-bg w-100 boxShadow p-1 my-1 br-10">
            <div onClick={() => videoModal(isVideo)}>
              {(
                <iframe
                  className="memory-scroll br-20 w-100"
                  src="https://www.youtube.com/embed/2DQ4arfP-ls?si=QqT2gJGkV10VVhtL"
                  style={{ pointerEvents: "none" }}
                ></iframe>
              ) || (
                <video
                  src={
                    "https://www.youtube.com/embed/2DQ4arfP-ls?si=QqT2gJGkV10VVhtL" ||
                    isVideo
                  }
                />
              )}
            </div>
          </div>

          {videoPlayModal && (
            <IonModal
              isOpen={videoPlayModal}
              onDidDismiss={() => {
                setIsVideo(null);
                setVideoPlayModal(false);
              }}
              canDismiss={true}
              centered
              className="custom-modal"
            >
              <div className="border-grey br-10">
              <div className="d-flex flex-column modal-content">
                <div
                  className="text-end"
                  onClick={() => setVideoPlayModal(false)}
                >
                  <IoClose className="font-30 clr-white" />
                </div>

                <div>
                  <iframe
                    src={
                      "https://www.youtube.com/embed/2DQ4arfP-ls?si=QqT2gJGkV10VVhtL" ||
                      isVideo
                    }
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: "100%", height: "500px", border: "none" }}
                  ></iframe>
                  {/* <video controls  autoPlay src={isVideo || "https://www.youtube.com/embed/2DQ4arfP-ls?si=QqT2gJGkV10VVhtL"} alt="select video"/> */}
                </div>
              </div>
              </div>
            </IonModal>
          )}
          <IonRow
            className="horizontal-scroll-container"
            style={{ overflowX: "auto" }}
          >
            {videos.map((trip, index) => (
              <IonCol
                size="5.8"
                key={index}
                class={`d-flex flex-column justify-content-start align-items-start  mx-1 p-1 br-10 ${
                  isVideo === trip?.path
                    ? "white-bg boxShadow border-pink"
                    : "white-bg"
                }`}
                onClick={() => handleVideo(trip?.path)}
              >
                <img class="" src={Images.TourVideo1 || trip.path} alt="" />
                <div class="fw-bold mt-1">{trip?.tour_title}</div>
                <div className="">
                  <MdOutlineCalendarMonth class="mr-1 " />
                  <span className="font-small">
                    {moment(trip?.created_at).format("DD-MM-YYYY")}
                  </span>
                </div>
              </IonCol>
            ))}
          </IonRow>
        </div>
      </div>

      <div className="white-bg p-1">
        <div className="d-flex justify-content-between flex-row align-items-center px-2 mt-1 upload-photos-btn">
          <div className="fw-bold font-20">Tour Photos</div>
        </div>
        <div className="px-2">
          <IonRow className="d-flex justify-content-between my-1">
            {Buttons.map((btn, index) => (
              <IonCol
                key={index}
                size="3.5"
                className={`text-center font-16 fw-bold br-20 py-2 ${
                  activeButtons === index
                    ? "deep-grey-bg clr-white"
                    : "light-grey-bg photos-clr"
                }`}
                onClick={() => handleButtons(index)}
              >
                {btn}
              </IonCol>
            ))}
          </IonRow>
        </div>
        {activeButtons === 0 && (
          <div className="px-2">
            <IonRow className="d-flex w-100">
              {photos.flat().map((photo, index) => (
                <IonCol key={index} size="6" className="pos-relative">
                  <img
                    src={Images?.AllPhoto1 || photo?.path}
                    className="w-100 m-1 photo-container"
                    alt=""
                    onClick={() => navigate(`/likes/${photo?.memory_id}`)}
                  />
                  <div className="w-90 px-2 clr-white d-flex justify-content-between pos-like-sec">
                    <div className="d-flex">
                      <FaHeart
                        className={`font-24 pointer ${
                          likedStatus[photo?.memory_id]
                            ? "clr-red"
                            : "clr-white"
                        } mr-1`}
                        onClick={() =>
                          handleLikes(photo?.memory_id, photo?.like_id)
                        }
                      />
                      <span className="clr-white ">
                        {photo?.total_likes_count}
                      </span>
                    </div>
                    <div className="d-flex">
                      <FaCommentDots className={`font-24 clr-white mr-1`} />
                      <span className="clr-white">{photo?.comments_count}</span>
                    </div>
                  </div>
                </IonCol>
              ))}
            </IonRow>
          </div>
        )}
        {activeButtons === 1 && (
          <div className="px-2">
            <IonRow className="d-flex w-100">
              {photos.map((latestimg, index) => (
                <IonCol key={index} size="6" className="pos-relative">
                  <img
                    src={Images?.AllPhoto1 || latestimg?.path}
                    className="w-100 m-1"
                    alt=""
                    onClick={() => navigate(`/likes/${latestimg?.memory_id}`)}
                  />
                  {/* <div className="pos-dots px-1 py-1">
                    {latestimg?.tour_title}
                  </div> */}

                  <div className="w-90 px-2 clr-white d-flex justify-content-between pos-like-sec">
                    <div className="d-flex">
                      <FaHeart
                        className={`font-24 pointer ${
                          likedStatus[latestimg?.memory_id]
                            ? "clr-red"
                            : "clr-white"
                        } mr-1`}
                        onClick={() =>
                          handleLikes(latestimg?.memory_id, latestimg?.like_id)
                        }
                      />
                      <span className="clr-white">
                        {latestimg?.total_likes_count}
                      </span>
                    </div>
                    <div className="d-flex">
                      <FaCommentDots className={`font-24 clr-white mr-1`} />
                      <span className="clr-white">
                        {latestimg?.comments_count}
                      </span>
                    </div>
                  </div>
                </IonCol>
              ))}
            </IonRow>
          </div>
        )}
        {activeButtons === 2 && (
          <div className="px-2">
            <IonRow className="d-flex justify-content-around w-100">
              {photos.map((popularimg, index) => (
                <IonCol key={index} size="5.8" className="pos-relative">
                  <img
                    src={Images?.AllPhoto1 || popularimg?.path}
                    className="w-100 m-1"
                    alt=""
                    onClick={() => navigate(`/likes/${popularimg?.memory_id}`)}
                  />

                  <div className="w-90 px-2 clr-white d-flex justify-content-between pos-like-sec">
                    <div className="d-flex">
                      <FaHeart
                        className={`font-24 pointer ${
                          likedStatus[popularimg?.memory_id]
                            ? "clr-red"
                            : "clr-white"
                        } mr-1`}
                        onClick={() =>
                          handleLikes(
                            popularimg?.memory_id,
                            popularimg?.like_id
                          )
                        }
                      />
                      <span className="clr-white">
                        {popularimg?.total_likes_count}
                      </span>
                    </div>
                    <div className="d-flex">
                      <FaCommentDots className={`font-24 clr-white mr-1`} />
                      <span className="clr-white">
                        {popularimg?.comments_count}
                      </span>
                    </div>
                  </div>
                </IonCol>
              ))}
            </IonRow>
          </div>
        )}
      </div>
    </div>
  );
}

export default Memories;
