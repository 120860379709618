import React from "react";
import { RxCross2 } from "react-icons/rx";
import { LiaCalendarDaySolid } from "react-icons/lia";
import { CiWallet } from "react-icons/ci";
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";
import { BiMessageDetail } from "react-icons/bi";
import { IoWarningOutline } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
import { IoIosLogOut } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";

function SideBar({ setShowMore }) {
  const menuItems = [
    {
      icon: <CiWallet className="grey-text font-24" />,
      text: "Wallet",
      path: "/wallet",
      data: {},
    },

    {
      icon: <MdOutlinePhotoSizeSelectActual className="grey-text font-24" />,
      text: "Memories",
      path: "/memories",
      data: {},
    },
    {
      icon: <BiMessageDetail className="grey-text font-24" />,
      text: "Chat",
      path: "/chat",
      data: {},
    },
    {
      icon: <IoWarningOutline className="grey-text font-24" />,
      text: "Complaints",
      path: "/complaints",
      data: {},
    },
    {
      icon: <IoSettingsOutline className="grey-text font-24" />,
      text: "Settings",
      path: "/settings",
      withArrow: true,
      data: {},
    },
    {
      icon: <IoIosLogOut className="grey-text font-24" />,
      text: "Log Out",
      path: "/logout",
      data: {},
    },
  ];

  const handleMenuItemClick = (path) => {
    if (path === "/logout") {
      localStorage.clear();
    }
    window.location.assign(path);
  };

  return (
    <div className="w-50 input-bg clr-black more-popup border-grey-not bg-none w-40 px-2">
      <div className="w-100 d-flex justify-content-end py-2 px-1">
        <RxCross2
          className="font-30 grey-text"
          onClick={() => setShowMore(false)}
        />
      </div>
      {menuItems.map((item, index) => (
        <div
          key={index}
          className="w-100 d-flex flex-row align-items-center justify-content-between white-bg py-2 br-10 font-16 grey-text px-2 my-2"
          onClick={() => handleMenuItemClick(item.path)}
          style={{ cursor: "pointer" }}
        >
          <div className="d-flex flex-row align-items-center">
            {item.icon}
            <div className="fw-600 mx-1">{item.text}</div>
          </div>
          {item.withArrow && <IoIosArrowDown className="grey-text font-24" />}
        </div>
      ))}

      {/* {menuItems.map((item, index) => (
        <div
          key={index}
          className="w-100 d-flex flex-row align-items-center justify-content-between white-bg py-2 br-10 font-16 grey-text px-2 my-2"
        >
          <div className="d-flex flex-row align-items-center">
            {item.icon}
            <div className="fw-600 mx-1">{item.text}</div>
          </div>
          {item.withArrow && <IoIosArrowDown className="grey-text font-24" />}
        </div>
      ))} */}
    </div>
  );
}

export default SideBar;
