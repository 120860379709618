import axios from "axios";
import environment from "../environments/environments.ts";
import baseUrl from "./../api-folder/baseUrl";
const token = localStorage.getItem("jwt_token");
const myid = localStorage.getItem("myid");

export default {
  async createRole(data) {
    try {
      const response = await axios.post(
        `${environment.baseUrl}/create-user`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error in creation:", error);
      throw error;
    }
  },

  getCreatedRoles: async () => {
    try {
      const response = await axios.get(
        `${environment.baseUrl}/get-created-users/${myid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Error in creation:", error);
      throw error;
    }
  },

  getEdituserDetails: async () => {
    try {
      const response = await axios.get(`${baseUrl}/get-user-details`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error", error);
    }
  },

  blockUser: async (id) => {
    try {
      const response = await axios.put(
        `${environment.baseUrl}/block-user/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error in blocking:", error);
      throw error;
    }
  },

  async editProfile(id, data) {
    try {
      const response = await axios.put(
        `${environment.baseUrl}/update-profile/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error(
        error.response.message || "An error occur while edit profile api"
      );
    }
  },

  updateUser: async (id, data) => {
    try {
      const response = await axios.put(
        `${environment.baseUrl}/update-user/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response;
    } catch (error) {
      console.error("Error in blocking:", error);
      throw error;
    }
  },
  getCurrnetUserDetails: async () => {
    try {
      const response = await axios.get(
        `${environment.baseUrl}/user-data/${myid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error in creation:", error);
      throw error;
    }
  },
};
