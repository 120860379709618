import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

const S3_BUCKET = "t2t";
const REGION = "ap-southeast-1";

AWS.config.update({
  accessKeyId: "AKIA3ZQZV5XBHJRBMBYI",
  secretAccessKey: "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",
  region: REGION,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const uploadFilesToS3 = (files, setProgress) => {
  return Promise.all(
    files.map((file) => {
      return new Promise((resolve, reject) => {
        const uniqueKey = `${Date.now()}-${uuidv4()}-${file.name}`;
        const params = {
          ACL: "public-read",
          Body: file,
          Bucket: S3_BUCKET,
          Key: uniqueKey,
        };

        myBucket
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            const progress = Math.round((evt.loaded / evt.total) * 100);
            setProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: progress,
            }));
          })
          .send((err) => {
            if (err) {
              reject(err);
            } else {
              const fileUrl = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${uniqueKey}`;
              resolve(fileUrl);
            }
          });
      });
    })
  );
};
