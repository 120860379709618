import React from "react";

const Logout = () => {
  return (
    <div>
      <center>
        <h1>Log outed from App </h1>
      </center>
    </div>
  );
};

export default Logout;
