import axios from "axios";
import environment from "../environments/environments.ts";

export default {
  loginapi(data) {
    return axios
      .post(`${environment.baseUrl}/login-user`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error :", error);
        throw error;
      });
  },
};
