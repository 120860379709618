import React, { useEffect, useState } from "react";
import { Images } from "../../images";
import { IonCol, IonRow } from "@ionic/react";
import { useNavigate } from "react-router";
import ForgetPassword from "../popup/ForgotPassword";
import { api } from "../../api";

function Otp() {
  const navigate = useNavigate();
  const [showForgoetPswdPopup, setShowForgetPswdPopup] = useState(false);
  const handleForgetPswdpage = () => {
    setShowForgetPswdPopup(true);
  };
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [user_id, setUser_id] = useState(0);
  console.log(user_id, "user_id");
  const [errorMessage, setErrorMessage] = useState("");

  const validatedPhone = `${countryCode} ${phoneNumber}`;

  useEffect(() => {
    let timer;

    if (isTimerActive && resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            setIsTimerActive(false);
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isTimerActive, resendTimer]);

  const handleOtpChange = (index, value) => {
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
    }
  };

  // forgot password
  const data = {
    country_code: countryCode,
    phone: phoneNumber,
  };
  console.log(data);

  const handleForgetPswd = async () => {
    if (!phoneNumber || !countryCode) {
      setErrorMessage(
        "Please enter both country code and phone number to receive the verification code."
      );
      return;
    }
    try {
      const response = await api.forgotPassword(data);
      setUser_id(response.data?.userId);
      setIsOtpSent(true);
      setResendTimer(60);
      setIsTimerActive(true);
      console.log(response, "response forgot password");
      setTimeout(() => {
        setErrorMessage("");
      }, 1000);
    } catch (error) {
      console.error(error.message, "error while doing forgot password");
    }
  };

  const dataOtp = {
    userId: user_id,
    otp: otp.join(""),
  };

  // verify otp
  const verificationOtp = async () => {
    try {
      const response = await api.verifyotp(dataOtp);
      console.log(response, "otp");
      return response;
    } catch (error) {
      console.error(error.message, "while doing verification otp");
    }
  };

  //const resend otp
  const handleresendOtp = async () => {
    try {
      const response = await api.resendOtp({
        userId: user_id,
        phone: phoneNumber,
      });
      console.log(response, "resendOtp================>>>>>>>>");
      setResendTimer(60);
      setIsTimerActive(true);
      return response;
    } catch (error) {
      console.error(error.message, "eror while resend otp");
    }
  };

  const handleSubmit = () => {
    if (!phoneNumber || !countryCode) {
      setErrorMessage(
        "Please Enter your Country Code and Phone Number to reset your password"
      );
      return;
    }
    setTimeout(() => {
      setErrorMessage("");
    }, 1000);
    handleForgetPswdpage();
    verificationOtp();
  };

  const handlePhoneNumber = (value) => {
    const numeric = value.replace(/\D/g, "");
    setPhoneNumber(numeric.slice(0, 10));
  };

  return (
    <>
      <div class="h-100vh d-flex justify-content-between align-items-center flex-column">
        <div className="d-flex flex-column w-100 justify-content-center align-items-center mt-5rem">
          <div className="w-100 d-flex justify-content-center p-4">
            <img src={Images.LoginLogo} className="logo-img" alt="" />
          </div>
          {showForgoetPswdPopup ? (
            <ForgetPassword user_id={user_id} />
          ) : (
            <>
              {" "}
              <div className="d-flex flex-column px-4">
                <h1 className="w-100 text-center fw-bold">OTP Verification</h1>
                <div className="w-100 d-flex align-items-center p-2">
                  <div className="large-font password-clr fw-bold w-20">
                    Phone No:-
                  </div>
                  <div className="d-flex w-80">
                    <input
                      className="w-20 input-bg py-2 mx-1 px-2 br-10 border-none bg-none"
                      placeholder="cc"
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                    />
                    <input
                      className="w-60 input-bg mx-1 py-2 px-2 br-10 border-none bg-none"
                      placeholder="Phone Number"
                      value={phoneNumber}
                      onChange={(e) => handlePhoneNumber(e.target.value)}
                    />
                    <div
                      className="red-bg  py-2 px-2 br-10 clr-white"
                      onClick={handleForgetPswd}
                    >
                      Send
                    </div>
                  </div>
                </div>
              </div>
              {errorMessage && (
                <div className="error-message clr-red mx-3 mt-2 px-3">
                  {errorMessage}
                </div>
              )}
              <div className="w-100 py-2 px-4 my-2">
                {isOtpSent && (
                  <>
                    <div className="font-14 fw-500 w-100 mt-1 text-center grey-text my-1">
                      We have sent a 6 digit code on your registered <br />
                      <div className="fw-600 clr-green">{validatedPhone}</div>
                    </div>
                    <IonRow className="my-2">
                      {otp.map((value, index) => (
                        <IonCol size="2" key={index}>
                          <div>
                            <input
                              id={`otp-input-${index}`}
                              className="input-bg br-10 my-1 font-18 py-1 w-100 text-center"
                              value={value}
                              onChange={(e) =>
                                handleOtpChange(index, e.target.value)
                              }
                              maxLength="1"
                            />
                          </div>
                        </IonCol>
                      ))}
                    </IonRow>
                    <div className="text-center d-flex my-1">
                      <span className="grey-text me-1">
                        Didn’t receive any code?
                      </span>
                      {isTimerActive ? (
                        <span className="grey-text fw-600">
                          Resend Code in
                          <span style={{ color: "green", margin: "2px" }}>
                            {resendTimer}s
                          </span>
                        </span>
                      ) : (
                        <span
                          className="clr-green fw-600"
                          onClick={handleresendOtp}
                        >
                          Resend Code
                        </span>
                      )}
                    </div>
                  </>
                )}
                <div
                  className="red-bg clr-white fw-600 py-3 mt-4 br-20 font-18 text-center"
                  // onClick={() => {
                  //   handleForgetPswdpage();
                  //   verificationOtp();
                  // }}
                  onClick={handleSubmit}
                >
                  Submit
                </div>
              </div>
            </>
          )}
        </div>
        <img src={Images.Loginbg} className="w-100" alt="" />
      </div>
    </>
  );
}

export default Otp;
