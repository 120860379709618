import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const CommonTable = ({
  data = [],
  footer = [],
  columns = [],
  itemsPerPage,
}) => {
  
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / itemsPerPage || 1);
  let startPage = Math.max(1, currentPage - Math.floor(5 / 2));
  let endPage = Math.min(totalPages, startPage + 5 - 1);
  if (endPage - startPage < 5 - 1) {
    startPage = Math.max(1, endPage - 5 + 1);
  }
  const pageButtons = [];
  for (let i = startPage; i <= endPage; i++) {
    pageButtons.push(
      <div
        key={i}
        onClick={() => handlePageChange(i)}
        className={`flex-center d-flex align-items-center px-2 me-1 font-14 br-50 ${
          currentPage === i ? "bg-blue clr-white" : " white-bg password-clr"
        }`}
      >
        {i}
      </div>
    );
  }
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, data.length);
  const currentData = data.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="w-100">
      <div className="w-100" style={{ overflowX: "auto" }}>
        <table
          className="w-100 match-position-table white-bg"
          style={{ whiteSpace: "nowrap" }}
        >
          <thead className="font-16 clr-white fw-600">
            <tr>
              {columns.map((column) => (
                <th key={column.key} className="text-start">
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="font-14 blue-text fw-500">
            {currentData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column) => (
                  <td key={column.key}>{row[column.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot className="font-16 fw-600">
            <tr>
              {footer?.map((column, footerIndex) => (
                <th
                  className="text-center small-font fw-bold p-2"
                  key={footerIndex}
                >
                  {column.header}
                </th>
              ))}
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="d-flex justify-content-end mt-2">
        <div className="d-flex">
          <div
            className="flex-center cursor-pointer white-bg br-50 p-1 font-16 mx-1"
            onClick={
              currentPage !== 1 ? () => handlePageChange(currentPage - 1) : null
            }
          >
            <FaChevronLeft />
          </div>
          {pageButtons}
          <div
            className="flex-center cursor-pointer white-bg br-50 p-1 font-16 mx-1"
            onClick={
              currentPage !== totalPages
                ? () => handlePageChange(currentPage + 1)
                : null
            }
          >
            <FaChevronRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonTable;
