export const setUserDetailsData = (data) => ({
  type: "SET_USER_DETAILS_DATA",
  payload: data,
});

export const setUploadPosterId = (id) => ({
  type: "SET_UPLOAD_POSTER_ID",
  payload: id,
});

export const setTourCategoryData = (data) => ({
  type: "SET_TOUR_CATEGORY_DATA",
  payload: data,
});

export const setTravelReportsData = (data) => ({
  type: "SET_TRAVEL_REPORTS_DATA",
  payload: data,
});
export const setSelectedUser = (data) => ({
  type: "SET_SELECTED_USER",
  payload: data,
});
export const setChangeHeader = (data) => ({
  type: "SET_CHANGE_HEADER",
  payload: data,
});
export const setClickedEdit = (data) => ({
  type: "SET_CLICKED_EDIT",
  payload: data,
});
export const setMessageContent = (data) => ({
  type: "SET_MESSAGE_CONTENT",
  payload: data,
});
export const setSelectedMessageId = (data) => ({
  type: "SET_MESSAGE_ID",
  payload: data,
});
export const setSendMessageText = (data) => ({
  type: "SET_MESSAGE_TEXT",
  payload: data,
});





