import { IonModal } from "@ionic/react";
import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import chatting from "../../api/chatting";

const BlockUser = ({ blockUser, setBlockUser, handleBlockUser }) => {
  const closeBlockpop = () => {
    setBlockUser(false);
  };
  const selectedUser = useSelector((state) => state?.selectedUser);

  const handleBlockTheUser = () => {
    const response = chatting.blockTheUser(selectedUser.userId);
    
    setBlockUser(false);
  };

  return (
    <div>
      <IonModal
        isOpen={blockUser}
        onDidDismiss={() => setBlockUser(false)}
        className="custom-modal"
      >
        <div className="white-bg br-10 p-1">
        <div className="w-100 d-flex justify-content-end">
          <RxCross2
            className="font-24 clr-black cursor-pointer mx-2 my-1"
            onClick={() => setBlockUser(false)}
          />
        </div>
        <div className="w-100 flex-center fw-700">
          Are You want to Block this User?
        </div>
        <div className="d-flex w-100 flex-evenly py-2 mb-2">
          <div
            className="pink-bg clr-white w-30 text-center font-16 fw-600 px-2 py-2 br-10"
            onClick={handleBlockTheUser}
          >
            Block
          </div>
          <div
            className="clr-black text-center font-16 w-30  fw-600 px-2 py-2 input-bg br-10 border"
            onClick={() => setBlockUser(false)}
          >
            Cancel
          </div>
        </div>
        </div>

      </IonModal>
    </div>
  );
};

export default BlockUser;
