import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { MdCloudUpload } from "react-icons/md";
import { IoIosCard } from "react-icons/io";
import { BsCheckCircleFill } from "react-icons/bs";
import { IonCol, IonModal, IonRow } from "@ionic/react";
import { RxCross2 } from "react-icons/rx";
import { IoIosArrowDown } from "react-icons/io";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { getBankDetailsByTourId } from "../../api-folder/apiMethods";
import { Images } from "../../images";

function BookNow({ showBookNowPopup, setShowBookNowPopup, tourId }) {
  const handleBooknowClose = () => {
    setShowBookNowPopup(false);
  };
  const [activeButton, setActiveButton] = useState(0);
  const [activeStep, setActiveStep] = useState(0);

  const handleButtonClick = (index) => {
    setActiveButton(index);
    setActiveStep(index);
    if (index === 0) {
      // Navigate to index 2
      setActiveStep(0);
      setActiveButton(0);
    } else if (index === 1) {
      // Navigate to index 3 when "Buy More" is clicked in index 1
      setActiveStep(1);
      setActiveButton(1);
    }
  };
  const buttons = [
    {
      icon: (
        <FaUser
          className={`font-24 ${
            activeStep >= 0 ? "clr-green" : "book-now-icon"
          }`}
        />
      ),
      index: 0,
    },
    {
      icon: (
        <MdCloudUpload
          className={`font-24 ${
            activeStep >= 1 ? "clr-green" : "book-now-icon"
          }`}
        />
      ),
      index: 1,
    },
    {
      icon: (
        <IoIosCard
          className={`font-24 ${
            activeStep >= 2 ? "clr-green" : "book-now-icon"
          }`}
        />
      ),
      index: 2,
    },
    {
      icon: (
        <BsCheckCircleFill
          className={`font-24 ${
            activeStep >= 3 ? "clr-green" : "book-now-icon"
          }`}
        />
      ),
      index: 3,
    },
  ];
  const packageData = [
    {
      sno: 1,
      selectedPkg: "Regular",
      pkgCount: 0,
      member: 0,
      minAmount: 500000.0,
    },
    {
      sno: 2,
      selectedPkg: "Premium",
      pkgCount: 0,
      member: 2,
      minAmount: 500000.0,
    },
    {
      sno: 3,
      selectedPkg: "Luxury",
      pkgCount: 0,
      member: 0,
      minAmount: 500000.0,
    },
    { sno: 4, selectedPkg: "VIP", pkgCount: 0, member: 0, minAmount: 500000.0 },
    {
      sno: 5,
      selectedPkg: "VVIP",
      pkgCount: 0,
      member: 0,
      minAmount: 500000.0,
    },
  ];
  const [selectedPackages, setSelectedPackages] = useState("");
  const [showPackages, setShowPackages] = useState(false);
  const handlePackagesBtn = () => {
    setShowPackages(!showPackages);
  };
  const handlePackagesSelection = (packageType) => {
    setSelectedPackages(packageType);
    setShowPackages(false);
  };

  const [showPaymentMethods, setShowPaymentMethods] = useState(false);

  const packagesOptions = [
    "Regular Package",
    "Premium Package",
    "Luxury Package",
    "VIP Package",
    "VVIP Package",
  ];
  const [showPackage, setShowPackage] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState("");

  const handlePackageBtn = () => {
    setShowPackage(!showPackage);
    setShowGender(false);
    setShowIdProof(false);
  };

  const handlePackageSelection = (packageType) => {
    setSelectedPackage(packageType);
    setShowPackage(false);
  };

  const packageOptions = [
    "Regular Package",
    "Premium Package",
    "Luxury Package",
    "VIP Package",
    "VVIP Package",
  ];
  const [showGender, setShowGender] = useState(false);
  const [selectedGender, setSelectedGender] = useState(null);
  const handleGenderBtn = () => {
    setShowPackage(false);
    setShowGender(!showGender);
    setShowIdProof(false);
  };

  const genderOptions = ["Male", "Female", "None"];

  const handleGenderSelection = (gender) => {
    setSelectedGender(gender);
    setShowGender(false);
  };
  const [showIDProof, setShowIdProof] = useState(false);
  const handleIdProofBtn = () => {
    setShowPackage(false);
    setShowGender(false);
    setShowIdProof(!showIDProof);
  };
  const [selectedIDProof, setSelectedIDProof] = useState("Select");

  const idProofs = [
    "Aadhar Card",
    "PanCard",
    "Driving License",
    "Bank Passbook",
  ];

  const selectIDProof = (proof) => {
    setSelectedIDProof(proof);
    setShowIdProof(false);
  };
  const [selectedPayment, setSelectedPayment] = useState("");
  const handlePaymentSelection = (paymentType) => {
    setSelectedPayment(paymentType);
    setShowPaymentMethods(false);
  };

  const handleSelectedPayment = () => {
    setShowPaymentMethods(!showPaymentMethods);
  };

  const [showCountryName, setShowCountryName] = useState(false);

  const handleCountry = () => {
    setShowCountryName(!showCountryName);
  };
  const [selectedCountry, setSelectedCountry] = useState("");

  const [fliteredGateways, setFilteredGateways] = useState([]);

  const handleCountrySelection = (country) => {
    setSelectedCountry(country);
    setShowCountryName(false);

    const filterPaymentMethod = bankData
      ?.filter((item) => item.country_name === country)
      ?.map((item) => item.gateway_name);
    setFilteredGateways(filterPaymentMethod);

    
  };

  const [bankData, setBankData] = useState([]);
  console.log(bankData, "bankData");
  const fetchbankDetails = () => {
    getBankDetailsByTourId(tourId)
      .then((response) => {
        if (response.status === true) {
          const data = response?.data;
          setBankData(data);
          console.log(response, "response");
        } else {
          console.log("error");
        }
      })
      .catch((error) => console.log(error.message));
  };

  useEffect(() => {
    fetchbankDetails();
  }, [tourId]);

  const paymentDetails = bankData?.find(
    (item) => item?.gateway_name === selectedPayment
  );

  const countryOptions = Array.from(
    new Set(bankData?.map((item) => item.country_name))
  );
  console.log(countryOptions, "countryOptions");

  return (
    <IonModal isOpen={showBookNowPopup} className="booknow-popup border-pink">
      <div className="white-bg">
        <div className="text-end" onClick={handleBooknowClose}>
          <RxCross2 className="font-30" />
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center px-1 py-1 border-bottom-grey">
          {buttons.map((button) => (
            <React.Fragment key={button.index}>
              <div
                className={`input-bg p-3 w-20 br-50 text-center ${
                  button.index === activeStep ? "grey-green-border" : ""
                }`}
                onClick={() => handleButtonClick(button.index)}
              >
                {button.icon}
              </div>
              {button.index < buttons.length - 1 && <div className="hr"></div>}
            </React.Fragment>
          ))}
        </div>
        {activeButton === 0 && (
          <div className="mt-2 px-2 pos-relative">
            <div
              className="input-bg br-10 py-2 d-flex flex-row justify-content-between align-items-center px-2 my-1"
              onClick={() => handlePackageBtn()}
            >
              <div className="password-clr font-16 fw-600">
                {selectedPackage || "Select Package"}
              </div>
              <IoIosArrowDown className="font-24 fw-600 password-clr" />
            </div>
            <div className="d-flex flex-column">
              <div className="fw-600 px-1 my-1 font-18 password-clr">Name</div>
              <div className="input-bg password-clr font-16 fw-600 br-10 py-2 d-flex flex-row justify-content-between align-items-center px-2 my-1">
                {selectedPackage || "Select Package"}
              </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between w-100">
              <div className="d-flex flex-column w-50">
                <div className="fw-600 px-1 my-1 font-18 password-clr">DOB</div>
                <input
                  type="date"
                  placeholder="DD/MM/YYYY"
                  className="input-bg password-clr font-16 fw-600 br-10 py-2 border-none mx-1"
                ></input>
              </div>
              <div className="d-flex flex-column w-50 ">
                <div className="fw-600 px-1 my-1 font-18 password-clr ">
                  Gender
                </div>
                <div
                  className="input-bg br-10 py-2 d-flex flex-row justify-content-between align-items-center px-2 mx-1"
                  onClick={() => handleGenderBtn()}
                >
                  <div className="grey-text font-16 fw-600">
                    {selectedGender ? selectedGender : "Select"}
                  </div>
                  <IoIosArrowDown className="font-24 fw-600 password-clr" />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="fw-600 px-1 my-1 font-18 password-clr">
                ID Proof
              </div>
              <div
                className="input-bg br-10 py-2 d-flex flex-row justify-content-between align-items-center px-2 my-1"
                onClick={() => handleIdProofBtn()}
              >
                <div className="grey-text font-16 fw-600">
                  {selectedIDProof}
                </div>
                <IoIosArrowDown className="font-24 fw-600 password-clr" />
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="fw-600 px-1 my-1 font-18 password-clr">
                Upload Photo
              </div>
              <div className="input-bg br-10 py-2 d-flex flex-row justify-content-between align-items-center px-2 my-1">
                <div className="grey-text font-16 fw-600">Upload Documents</div>
                <MdCloudUpload className="font-24 fw-600 password-clr" />
              </div>
            </div>
            <div className="red-bg py-2 br-20 clr-white fw-bold text-center my-2">
              Save
            </div>
            {showPackage && (
              <div className="white-bg password-clr border-pink package-dropdown br-10 px-2">
                {packageOptions.map((packagesType, index) => (
                  <div
                    key={index}
                    className={`font-16 fw-600 py-1 ${
                      index < packageOptions.length - 1
                        ? "border-bottom-grey"
                        : ""
                    }`}
                    onClick={() => handlePackageSelection(packagesType)}
                  >
                    {packagesType}
                  </div>
                ))}
              </div>
            )}
            {showGender && (
              <div className="white-bg password-clr border-pink gender-dropdown br-10 px-2">
                {genderOptions.map((gender) => (
                  <div
                    key={gender}
                    className="font-16 fw-600 py-1 border-bottom-grey"
                    onClick={() => handleGenderSelection(gender)}
                  >
                    {gender}
                  </div>
                ))}
              </div>
            )}
            {showIDProof && (
              <div className="white-bg password-clr border-pink id-dropdown br-10 px-2">
                {idProofs.map((proof, index) => (
                  <div
                    key={index}
                    className="font-16 fw-600 py-1 border-bottom-grey"
                    onClick={() => selectIDProof(proof)}
                  >
                    {proof}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {activeButton === 1 && (
          <div className="mt-2 ">
            <h1 className="password-clr font-24 fw-bold w-100 text-center">
              Added Users Details
            </h1>
            <IonRow className="booknow-table-header py-1 clr-white font-16 fw-600 d-flex align-items-center">
              <IonCol size="1.4" className="text-center">
                Sno
              </IonCol>
              <IonCol size="3" className="text-center">
                Selected Pkg
              </IonCol>
              <IonCol size="2.2" className="text-center">
                Pkg Count
              </IonCol>
              <IonCol size="2.4" className="text-center">
                Member
              </IonCol>
              <IonCol size="3" className="text-center">
                Min Amount
              </IonCol>
            </IonRow>
            {packageData.map((data) => (
              <IonRow
                key={data.sno}
                className="border-bottom-grey password-clr font-16 fw-600 d-flex align-items-center py-1"
              >
                <IonCol size="1.4" className="text-center">
                  {data.sno}
                </IonCol>
                <IonCol size="3" className="text-center">
                  {data.selectedPkg}
                </IonCol>
                <IonCol size="2.2" className="text-center">
                  {data.pkgCount}
                </IonCol>
                <IonCol size="2.4" className="text-center">
                  {data.member}
                </IonCol>
                <IonCol size="3" className="text-center">
                  {data.minAmount}
                </IonCol>
              </IonRow>
            ))}

            <IonRow className="input-bg py-2 password-clr font-16 fw-600">
              <IonCol size="4">Total Amount</IonCol>
              <IonCol size="4" className="fw-bold">
                500000.00
              </IonCol>
              <IonCol size="4" className="fw-bold">
                100000000.00
              </IonCol>
            </IonRow>
            <div className="d-flex flex-row w-100">
              <div className="red-bg py-2 br-20 clr-white fw-bold text-center my-2 w-50 mx-1">
                Buy More
              </div>
              <div className="red-bg py-2 br-20 clr-white fw-bold text-center my-2 w-50 mx-1">
                Proceed to Pay
              </div>
            </div>
          </div>
        )}
        {activeButton === 2 && (
          <div className="mt-1  pos-relative">
            <h1 className="password-clr font-24 fw-bold w-100 text-center">
              Payment Details
            </h1>
            <div className="booknow-table-header py-1 clr-white  p-1 br-10">
              <div className="font-16 fw-600 d-flex flex-row clr-white border-bottom-grey d-flex align-items-center justify-content-between py-1">
                <div>Select Members</div>
                <div> 2</div>
              </div>
              <div className="d-flex flex-row clr-white border-bottom-grey d-flex align-items-center justify-content-between py-1">
                <div className="d-flex flex-column font-16 fw-600">
                  <div>Member 01</div>
                  <div>Jayanta Pal, Male, 24</div>
                </div>
                <div className="w-20 pink-bg py-1 br-20 font-16 fw-600 text-center">
                  View ID
                </div>
              </div>
              <div className="d-flex flex-row clr-white border-bottom-grey d-flex align-items-center justify-content-between py-1">
                <div className="d-flex flex-column font-16 fw-600">
                  <div>Member 02</div>
                  <div>Jayanta Pal, Male, 24</div>
                </div>
                <div className="w-20 pink-bg py-1 br-20 font-16 fw-600 text-center">
                  View ID
                </div>
              </div>
              <div className="font-16 fw-600 d-flex flex-row clr-white border-bottom-grey d-flex align-items-center justify-content-between py-1">
                <div>Package Amount</div>
                <div>1000000000000</div>
              </div>
              <div className="font-16 fw-600 d-flex flex-row clr-white border-bottom-grey d-flex align-items-center justify-content-between py-1">
                <div>Total Members</div>
                <div> 2</div>
              </div>
            </div>
            <div className="input-bg py-2 d-flex align-items-center justify-content-between password-clr fw-600 font-16 px-2">
              <div>Total Amount to Pay</div>
              <div>100000000.00</div>
            </div>
            <div className="px-4 overflow-y-scroll">
              <div className="d-flex flex-column">
                <div className="font-16 fw-600 ">Country</div>
                <div
                  className="input-bg br-10 py-1 d-flex flex-row justify-content-between align-items-center px-2 my-1"
                  onClick={() => handleCountry()}
                >
                  <div className="password-clr font-16 fw-600">
                    {selectedCountry || "select Country"}
                  </div>
                  <IoIosArrowDown className="font-24 fw-600 password-clr" />
                </div>
                {showCountryName && (
                  <div className="input-bg grey-text br-10 payment-dropdown">
                    {countryOptions.map((item, index) => (
                      <div
                        key={index}
                        className={`red-border medium-font fw-600 p-2 ${
                          index < countryOptions.length - 1
                            ? "border-bottom-grey"
                            : ""
                        }`}
                        onClick={() => handleCountrySelection(item)}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                )}

                <div className="font-16 fw-600 ">Payment Mode</div>
                <div
                  className="input-bg br-10 py-1 d-flex flex-row justify-content-between align-items-center px-2 my-1"
                  onClick={() => handleSelectedPayment()}
                >
                  <div className="password-clr font-16 fw-600">
                    {selectedPayment || "select Payment"}
                  </div>
                  <IoIosArrowDown className="font-24 fw-600 password-clr" />
                </div>

                {showPaymentMethods && (
                  <div className="input-bg grey-text br-10 payment-dropdown">
                    {fliteredGateways.map((paymentType, index) => (
                      <div
                        key={index}
                        className={`red-border medium-font fw-600 p-2 ${
                          index < fliteredGateways.length - 1
                            ? "border-bottom-grey"
                            : ""
                        }`}
                        onClick={() => handlePaymentSelection(paymentType)}
                      >
                        {paymentType}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {(selectedPayment === "phone_pe" ||
                selectedPayment === "g_pay" ||
                selectedPayment === "paytm") && (
                <div className="input-bg br-10 p-2 flex-column password-clr medium-font fw-600 my-1">
                  <div>MobileNumber :{paymentDetails?.mobile_number}</div>
                  <div>UPIID: {paymentDetails?.upi_id}</div>
                  <div>UPINAME : {paymentDetails?.upi_name}</div>
                </div>
              )}
              {selectedPayment === "bankdetails" && (
                <div className="input-bg br-10 p-2 flex-column password-clr medium-font fw-600 my-1">
                  <div>Bank_Name : {paymentDetails?.bank_name}</div>
                  <div>Account Number : {paymentDetails?.bank_acc_no}</div>
                  <div>IFSC: {paymentDetails?.bank_ifsc_code}</div>
                </div>
              )}
              {selectedPayment === "qr_code" && (
                <div className="input-bg br-10 p-2 flex-column password-clr medium-font fw-600 my-1">
                  <div>Qr_Name : {paymentDetails?.qr_name}</div>
                  <img
                    src={Images.CasinoBanner || paymentDetails?.qr_upload_path}
                    alt="QR Code"
                    className="mt-1 br-10 h-5h"
                  />
                </div>
              )}

              <div className="input-bg br-10 py-2 d-flex flex-row justify-content-between align-items-center px-2 my-1">
                <div className="grey-text font-16 fw-600">Upload Documents</div>
                <MdCloudUpload className="font-24 fw-600 password-clr" />
              </div>
            </div>

            <div className="red-bg py-2 br-20 clr-white fw-bold text-center my-2">
              Proceed To Pay
            </div>
          </div>
        )}

        {activeButton === 3 && (
          <div className="mt-2 px-2">
            <div className="d-flex justify-content-center w-100 my-2">
              <IoCheckmarkCircleSharp
                className="font-40 clr-green"
                style={{ fontSize: "100px" }}
              />
            </div>
            <div className="px-2">
              <h1 className="w-100 password-clr text-center fw-bold my-1">
                Congratulation!
              </h1>
              <h4 className="w-100 password-clr text-center fw-bold my-1">
                Your Booking is Completed
              </h4>
              <span className="font-16 grey-text my-1 fw-600">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
              </span>
              <div className="red-bg py-2 br-20 clr-white fw-bold text-center my-2">
                Go Back to Message
              </div>
            </div>
          </div>
        )}
      </div>
    </IonModal>
  );
}

export default BookNow;
